import {
  AnyMxSelectorCreatorMatching,
  MxSelector,
  MxSelectorCreator,
  MxSelectorCreatorNameMatching,
  MxSelectorCreatorNameMatchingWithReturnType,
  MxSelectorNameMatching
} from '@store/store.types';
import {Type} from '@angular/core';
import {OneOrArrayOf} from '@store/utility-type.helpers';
import {Action} from '@ngrx/store';
import {StatusChipColors} from '@store/common/common.types';
import {Format} from '../core/shared/pipes/format.pipe';

export interface LoadableSelectorContainer {
  awaitingFirstLoad: MxSelector<boolean>;
  awaitingAnyLoad: MxSelector<boolean>;
}

export type SelectorCreatorOrSelectorCreatorName<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer, T> =
  MxSelectorCreatorNameMatchingWithReturnType<ObjectType, DefaultSelectorContainer, T> | MxSelectorCreator<[ObjectType], T>;

export type SelectorCreatorOrSelectorCreatorNameOrSimply<T, ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> =
  T | SelectorCreatorOrSelectorCreatorName<ObjectType, DefaultSelectorContainer, T>;


export interface TableDefinition<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> {
  /** REQUIRED.  The name of a namespace containing the selectors and selector creators that will be used (by default) in the rest of this definition. */
  defaultSelectorContainer: DefaultSelectorContainer;

  /**
   * REQUIRED.  The selector that contains the array of data that will be displayed by this table.
   *
   * If this is a string, it is assumed to be the name of a selector found in `defaultSelectorContainer`.  If not, then it's a direct reference to an existing
   * selector, which could be local or found inside some other selector namespace.
   */
  dataSource: MxSelectorNameMatching<ObjectType[], DefaultSelectorContainer> | MxSelector<ObjectType[]>;

  /**
   * OPTIONAL.  Defaults to an empty object.  The keys to this object are class names.  The values are boolean selector creator names.  Each class name whose
   * selector returns `true` for the current row will be applied to the table row element.
   */
  rowCssClasses?: RowCssClassDefinition<ObjectType, DefaultSelectorContainer>;

  /** OPTIONAL.  Defaults to `false`.  If `true`, a checkbox select column is added to the left of the first defined column. */
  enableRowSelection?: boolean;

  /**
   * OPTIONAL.  Ignored when `enableRowSelection` is `false`.  Defaults to `undefined`, which means that all rows are always selectable.  This is a boolean
   * selector creator which will be invoked for each row.  If it returns false for some row, that row's selection checkbox will be disabled, and the
   * "select all" header checkbox will function as if that row doesn't exist.
   */
  individualRowIsSelectable?: SelectorCreatorOrSelectorCreatorName<ObjectType, DefaultSelectorContainer, boolean>;

  /**
   * OPTIONAL, but encouraged.  Ignored when `enableRowSelection` is `false`.  Defaults to `undefined`, which means no tooltip.  This
   * is a boolean
   * selector creator which will be invoked for each row.  If it returns false for some row, that row's selection checkbox will be disabled, and the
   * "select all" header checkbox will function as if that row doesn't exist.
   */
  rowSelectionTooltips?: RowSelectionTooltips<ObjectType, DefaultSelectorContainer>;

  /** OPTIONAL.  Defaults to `undefined`.  Ignored when `enableRowSelection` is `false`.  Requires an action creator whose payload is an array of row objects.
   *  Whenever the set of selected rows changes, this action will be dispatched with an array of the row objects that are currently selected, which could be an
   *  empty array.
   */
  rowSelectionChangeAction?: ActionCreator<ObjectType[]>;

  /** OPTIONAL.  Defaults to an empty array.  Ignored when expansionRowData is populated.
   *
   * If this array is populated, an expansion arrow is added as the first column, and expansion data is rendered for the current row from this array.
   * Columns are aligned with existing data columns in the order they're defined. If there are more items in this array than there are data columns, the extras
   * will wrap to more than one row in the expansion panel.
   *
   * Ignored for each row where `showExpansionControlIf` is `false`.
   */
  expansionCells?: ExpansionCellDefinition<ObjectType, DefaultSelectorContainer>[];

  /**
   * OPTIONAL.  A boolean selector creator name or boolean selector creator.  Defaults to `undefined`, meaning that all rows will show an expansion control.
   *
   * Ignored when `expansionCells` is empty.
   *
   * For each row, if the value is `true`, then the expansion control is shown on that row.  Otherwise, it is not shown.
   *
   * If it is a string, it is assumed to be the name of a selector found in `defaultSelectorContainer`.  Otherwise, it's a direct reference to an existing
   * selector, which could be local or found inside some other selector namespace.
   */
  showExpansionControlIf?: SelectorCreatorOrSelectorCreatorName<ObjectType, DefaultSelectorContainer, boolean>;

  /** OPTIONAL.  Defaults to `false`.  Ignored when expansionRowData is populated.  If `true`, an expansion arrow is added as the first column, and the
   *  component defined by `expansionRowComponentClass` is rendered for the current row.
   *
   *  @deprecated Previously uses of this property have been replaced with `expansionCells`, but we foresee some need for a column-spanning expansion row
   *  functionality, so we're keeping it here for the time being.  Unfortunately, expansion rows are now implemented as synchronized expansion cells instead, so
   *  if we need this again, we'll have to come up with a way to implement it and also keep the existing outline box and accompanying animation transitions.
   *  */
  enableExpansionRowComponent?: boolean;

  /** OPTIONAL.  Defaults to `undefined`.  Ignored when `enableExpansionRowComponent` is `false`. Component will show in the following row when the expansion
   * arrow is toggled. The row object will be passed as an @Input parameter to the class.
   *
   * @deprecated - See deprecation comment for `enableExpansionRowComponent`.
   */
  expansionRowComponentClass?: Type<any>; // eslint-disable-line @typescript-eslint/no-explicit-any -- Generic component typing is difficult, maybe impossible.

  /** REQUIRED.  An array of `ColumnDefinition` objects. */
  columns: ColumnDefinition<ObjectType, DefaultSelectorContainer>[];

  /** OPTIONAL.  An array of `ActionIconButtonDefinition` objects.  Buttons defined here will appear to the immediate left of the kebab menu, or on the right
   * end of each row if there is no kebab menu specified.
   */
  actionIconButtons?: ActionIconButtonDefinition<ObjectType, DefaultSelectorContainer>[];

  /** OPTIONAL.  An array of `KebabActionDefinition` objects.  Menu items defined here will appear in a popup menu that is activated by a kebab icon.
   *  That icon is positioned at the right end of each row.
   */
  kebabMenuActions?: KebabActionDefinition<ObjectType, DefaultSelectorContainer>[];

  /** OPTIONAL.  When this component is specified, it will be added dynamically instead of that default whenever the table has no rows to display.  Otherwise,
   *  a simple string will be displayed when there are no rows -- either `noDateRowMessage` or its default value.
   */
  noDataComponentClass?: Type<any>; // eslint-disable-line @typescript-eslint/no-explicit-any -- Generic component typing is difficult, maybe impossible.

  /** OPTIONAL.  Defaults to "No items to show.".  Ignored when `noDataComponentClass` is defined. */
  noDataRowMessage?: string;

  /** OPTIONAL. Defaults to `true`. */
  includePaginationControls?: boolean;

  /** OPTIONAL.  Defaults to `undefined`.  If this is defined, then sort requests for this table are sent with this ID.  Useful on pages that have multiple
   *  copies of the same type of table.  Our routing effects will create route query parameters such that they properly differentiate sorts on different tables.
   *
   *  If this is a string, it is assumed to be the name of a selector found in `defaultSelectorContainer`.  If not, then it's a direct reference to an existing
   *  selector, which could be local or found inside some other selector namespace.
   */
  entityId?: MxSelectorNameMatching<number, DefaultSelectorContainer> | MxSelector<number>;

  /** OPTIONAL.  Defaults to `undefined`.  If this is defined, the table can show a hidden indicator row, depending on the options set herein. */
  hiddenDataIndicatorRow?: HiddenDataIndicatorRowDefinition<DefaultSelectorContainer>;
}

export interface RowCssClassDefinition<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> {
  [className: string]: MxSelectorCreatorNameMatchingWithReturnType<ObjectType, DefaultSelectorContainer, boolean>;
}

export interface RowSelectionTooltips<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> {
  whenUnselected: SelectorCreatorOrSelectorCreatorNameOrSimply<string, ObjectType, DefaultSelectorContainer>;
  whenSelected: SelectorCreatorOrSelectorCreatorNameOrSimply<string, ObjectType, DefaultSelectorContainer>;
  whenDisabled: SelectorCreatorOrSelectorCreatorNameOrSimply<string, ObjectType, DefaultSelectorContainer>;
}

export type ColumnDefinition<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> =
  TextColumnDefinition<ObjectType, DefaultSelectorContainer>
  | SimpleLinkColumnDefinition<ObjectType, DefaultSelectorContainer>
  | ActionLinkColumnDefinition<ObjectType, DefaultSelectorContainer>
  | RouteNameLinkColumnDefinition<ObjectType, DefaultSelectorContainer>
  | ChipColumnDefinition<ObjectType, DefaultSelectorContainer>
  | StatusChipColumnDefinition<ObjectType, DefaultSelectorContainer>
  | ComponentColumnDefinition<ObjectType, DefaultSelectorContainer>;

interface StandardColumnDefinition<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> {
  /**
   * REQUIRED.  The selector creator that takes the data for one row of data and emits the appropriate value for the cell in this column and that row.
   *
   * If this is a string, it is assumed to be the name of a selector creator found in `defaultSelectorContainer`.  If not, then it's a direct reference to an
   * existing selector creator, which could be local or found inside some other selector namespace.
   */
  value: MxSelectorCreatorNameMatching<ObjectType, DefaultSelectorContainer> | AnyMxSelectorCreatorMatching<ObjectType>;

  /** OPTIONAL.  Defaults to `value`, but converted for display.  (Example: `'firstNameFor'` &rarr; `'First Name'`) */
  header?: string;

  /** OPTIONAL.  Defaults to an empty string.  This is a space-separated list of any classname(s) that should be applied to the column's header.  This is
   *  usually used to set a specific column width like `'w-[42%]'` or `'w-[4rem]'`
   */
  headerCssClasses?: string;

  /** OPTIONAL.  Defaults to an empty string. Text for matToolTip on column header */
  headerToolTip?: string;

  /** OPTIONAL.  Defaults to `false`. */
  sortable?: boolean;

  /**
   * OPTIONAL. Defaults to `selectorCreatorName`, but without the `'For'` suffix.  (Example: `'firstNameFor'` &rarr; `'firstName'`).
   * Ignored when `sortable` property is `false`.
   */
  sortKey?: string;

  /**
   * OPTIONAL.  Defaults to `'NONE'`, which means to display cell values in this column as-is.  `FormatPipe` will be invoked with the format(s) specified here.
   */
  format?: OneOrArrayOf<Format>;

  /**
   * OPTIONAL.  Defaults to an empty object.  The keys to this object are class names.  The values are boolean selector creator names.  Each class name whose
   * selector returns `true` for the current row will be applied to the current cell.  To always include the class, specify the special constant `ALWAYS`
   * instead of the function.
   */
  cssClasses?: CellCssClassDefinition<ObjectType, DefaultSelectorContainer>;

  /**
   * OPTIONAL.  Defaults to `false`.  If `true`, the column's data will be truncated if it exceeds the column's width.
   */
  truncate?: boolean;
}

export interface TextColumnDefinition<
  ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer
> extends StandardColumnDefinition<ObjectType, DefaultSelectorContainer> {
  type: 'text';
}

export interface SimpleLinkColumnDefinition<
  ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer
> extends StandardColumnDefinition<ObjectType, DefaultSelectorContainer> {
  type: 'simpleLink';

  /** REQUIRED.  Cells in this column are rendered as links whose `(click)` handlers are this function. The row object is sent to this handler. */
  clickHandler: ClickHandler<ObjectType>;
}

export interface ActionLinkColumnDefinition<
  ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer
> extends StandardColumnDefinition<ObjectType, DefaultSelectorContainer> {
  type: 'actionLink';
  /**
   * REQUIRED.  Cells in this column are rendered as links whose `(click)` handlers are functions that create and dispatch the indicated action based on the
   * row object.
   */
  actionCreator: ActionCreator<ObjectType>;
}

export interface RouteNameLinkColumnDefinition<
  ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer
> extends StandardColumnDefinition<ObjectType, DefaultSelectorContainer> {
  type: 'routeNameLink';

  /**
   * REQUIRED.  Cells in this column are rendered as links with an `[hkNamedRouterLink]` directive, so this must match a predefined route name
   * (that is, a `data.name` property from a route).
   */
  routeName: string;

  /**
   * Technically OPTIONAL, but actually REQUIRED when the path corresponding to `routeName` includes one or more substitution parameters.  Defaults to an empty
   * object. When required, this object must contain a mapping from route path parameter names to names of existing selector creators.
   *
   * Examples:
   * <ol>
   *   <li>
   *     Given a route path <code>'/some/path/with/:id'</code>, and a selector creator named <code>idFor()</code>, this object should be
   *     <code>{ id: 'idFor' }</code>.
   *   </li>
   *   <li>
   *     Given the same route path, but a selector creator named <code>someIdFor()</code>, this object should be <code>{ id: 'someIdFor' }</code>.
   *   </li>
   * </ol
   */
  routeParams?: LinkRouteParamSelectorCreatorNames<ObjectType, DefaultSelectorContainer>;
}

export interface LinkRouteParamSelectorCreatorNames<ObjectType, DefaultSelectorContainer extends object> {
  [routeParamName: string]: MxSelectorCreatorNameMatching<ObjectType, DefaultSelectorContainer> | AnyMxSelectorCreatorMatching<ObjectType>;
}

export interface LinkRouteParamSelectedSelectorCreators {
  [routeParamName: string]: unknown;
}

export interface ChipColumnDefinition<
  ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer
> extends StandardColumnDefinition<ObjectType, DefaultSelectorContainer> {
  type: 'chip';

  /** OPTIONAL.  Defaults to an empty string, meaning the chip will get a default color (which is probably not usually what we want).
   *  This is a chip color selector creator which will be invoked for each row.
   */
  chipColor?: SelectorCreatorOrSelectorCreatorName<ObjectType, DefaultSelectorContainer, string>;
}

export interface StatusChipColumnDefinition<
  ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer
> extends StandardColumnDefinition<ObjectType, DefaultSelectorContainer> {
  type: 'statusChip';

  /** REQUIRED.  An object that maps status colors to the list of statuses that we want to display with that color.  Note that this is not as strongly
   *  typed as we would like, which would be `StatusChipColors<ValueType>`, where `ValueType` is the type of the value returned by the `value` property's
   *  selector creator.  If all your chips are grey, make sure you picked the right object for this.
   */
  statusChipColors: StatusChipColors<string /* ValueType */>;

  /** OPTIONAL.  A Map of status names to names we would like to display instead.  Note that this is not as strongly typed as we would like, which would be
   *  `Map<ValueType, string>`, where `ValueType` is the type of the value returned by the `value` property's selector creator.  If your displayable values
   *  aren't replacing the actual values, make sure you specified the right map for this.
   */
  displayableStatusNames?: Map<string /* ValueType */, string>;

  /*
    IMPLEMENTATION NOTE:  We don't have the types we would prefer here due to a missing feature in TypeScript.  We'd want to be able to specify the return
    type of the value selector creator, (call it ValueType), and use that as noted in the properties above.  But then we'd need to ripple that third type
    through most of our column definitions, like <ObjectType, DefaultSelectorContainer, ValueType>.  And ultimately, we'd need to reflect that change in
    TableDefinition's columns property, which is currently typed as ColumnDefinition<ObjectType, DefaultSelectorContainer>[].

    But what would we want there?  Something like ColumnDefinition<ObjectType, DefaultSelectorContainer, WhateverValueTypeThisColumnRepresents>[], but
    TypeScript has no way to express that.  (If we just use "any" for that third type, we might as well not do anything at all, because ValueType just becomes
    "any" on each column type, and that's even worse than "string".

    This concept is known as "existential generic types", and is described in more detail on these pages and many others:
      - https://stackoverflow.com/questions/65129070/defining-an-array-of-differing-generic-types-in-typescript
      - https://github.com/microsoft/TypeScript/issues/14466
   */
}

export interface ComponentColumnDefinition<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> {
  type: 'component';

  columnName: string;

  /** OPTIONAL.  Defaults to `columnName`, but converted for display.  (Example: `'firstName'` &rarr; `'First Name'`) */
  header?: string;

  /** OPTIONAL.  Defaults to an empty string.  This is a space-separated list of any classname(s) that should be applied to the column's header.  This is
   *  usually used to set a specific column width like `'w-[42%]'` or `'w-[4rem]'`
   */
  headerCssClasses?: string;

  /** OPTIONAL.  Defaults to an empty string. Text for matToolTip on column header */
  headerToolTip?: string;

  /** OPTIONAL.  Defaults to `false`. */
  sortable?: boolean;

  /** OPTIONAL. Defaults to `columnName`.  Ignored when `sortable` property is `false`. */
  sortKey?: string;

  componentClass: Type<any>; // eslint-disable-line @typescript-eslint/no-explicit-any -- Generic component typing is difficult, maybe impossible.

  componentProperties?: CellComponentProperties<ObjectType, DefaultSelectorContainer>;
}

export const CURRENT_ROW_OBJECT = 'CURRENT_ROW_OBJECT';
export const CURRENT_SELECTORS_CONTAINER = 'CURRENT_SELECTORS_CONTAINER';

export interface CellComponentProperties<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> {
  [propertyName: string]: CellComponentProperty<ObjectType, DefaultSelectorContainer>;
}

export type CellComponentProperty<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> =
  MxSelectorCreatorNameMatching<ObjectType, DefaultSelectorContainer> | AnyMxSelectorCreatorMatching<ObjectType> | boolean | string | string[];

export interface ActionIconButtonDefinition<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> {
  /** REQUIRED.  The name of a Material icon. */
  iconName: string;

  color?: SelectorCreatorOrSelectorCreatorName<ObjectType, DefaultSelectorContainer, string>;

  /**
   * OPTIONAL.  Defaults to `undefined`.  If this is a defined action creator function, the button's `(click)` handler is a function that creates and
   * dispatches the indicated action based on the row object.
   */
  clickActionCreator?: ActionCreator<ObjectType>;

  /**
   * OPTIONAL.  Defaults to `undefined`.  Ignored when `clickActionCreator` is defined.  If this is a defined function, it is used as the button's `(click)`
   * handler. The row object is sent to this handler.
   */
  clickHandler?: ClickHandler<ObjectType>;

  /**
   * OPTIONAL.  Defaults to an empty object.  The keys to this object are class names.  The values are boolean selector creator names.  Each class name whose
   * selector returns `true` for the current row will be applied to the current cell.  To always include the class, specify the special constant `ALWAYS`
   * instead of the function.
   */
  cssClasses?: CellCssClassDefinition<ObjectType, DefaultSelectorContainer>;

  /** OPTIONAL.  Defaults to `undefined`, which means no tooltip.  This is a string selector creator which will be invoked for each row.  A simple string
   *  is also accepted -- if it doesn't contain the name of a selector creator in `selectorsContainer`, then it will be displayed as-is. */
  tooltip?: SelectorCreatorOrSelectorCreatorNameOrSimply<string, ObjectType, DefaultSelectorContainer>;

  /** OPTIONAL.  Defaults to `undefined`, which means no badge.  This is a string selector creator which will be invoked for each row.  A simple string
   *  is also accepted -- if it doesn't contain the name of a selector creator in `selectorsContainer`, then it will be displayed as-is. */
  badge?: SelectorCreatorOrSelectorCreatorNameOrSimply<string, ObjectType, DefaultSelectorContainer>;

  /** OPTIONAL.  Defaults to `undefined`, which means no superscript.  IGNORED if `badge` is also defined.  This is a string selector creator which will be
   *  invoked for each row.  A simple string is also accepted -- if it doesn't contain the name of a selector creator in `selectorsContainer`, then it will be
   *  displayed as-is. */
  superscript?: SelectorCreatorOrSelectorCreatorNameOrSimply<string, ObjectType, DefaultSelectorContainer>;

  /**
   * OPTIONAL.  Defaults to `undefined`, which means the button is visible.  This is a boolean selector creator which will be invoked for each row.
   */
  visible?: SelectorCreatorOrSelectorCreatorName<ObjectType, DefaultSelectorContainer, boolean>;

  /**
   * OPTIONAL.  Defaults to `undefined`, which means the button is enabled.  This is a boolean selector creator which will be invoked for each row.
   */
  enabled?: SelectorCreatorOrSelectorCreatorName<ObjectType, DefaultSelectorContainer, boolean>;
}

export interface KebabActionDefinition<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> {
  /** REQUIRED.  The label for the kebab menu item. */
  label: string;

  /** OPTIONAL. Defaults to `undefined`, which means "always visible".  If this function is specified, it will be invoked with the row object, and the kebab
   *  menu item will be included if the function returns `true`.
   */
  visible?: SelectorCreatorOrSelectorCreatorName<ObjectType, DefaultSelectorContainer, boolean>;

  /**
   * OPTIONAL.  Defaults to `undefined`.  If this is a defined action creator function, the kebab menu item's `(click)` handler is a function that creates and
   * dispatches the indicated action based on the row object.
   */
  clickActionCreator?: ActionCreator<ObjectType>;

  /**
   * OPTIONAL.  Defaults to `undefined`.  Ignored when `clickActionCreator` is defined.  If this is a defined function, it is used as the kebab menu item's
   * `(click)` handler. The row object is sent to this handler.
   */
  clickHandler?: ClickHandler<ObjectType>;
}

export const ALWAYS = 'ALWAYS';

export interface CellCssClassDefinition<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> {
  [className: string]: typeof ALWAYS | SelectorCreatorOrSelectorCreatorName<ObjectType, DefaultSelectorContainer, boolean>;
}

export type ClickHandler<ObjectType> = (rowObject: ObjectType) => void;
export type NoArgumentClickHandler = () => void;
export type ActionCreator<ObjectType> = (rowObject: ObjectType) => Action;
export type NoArgumentActionCreator = () => Action;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- We need generic types for ExpansionCellDefinition.  But for this constant, we don't use them.
export const EMPTY_EXPANSION_CELL: TextExpansionCellDefinition<any, any> = {
  type: 'text',
  header: '',
  value: ''
};

export type ExpansionCellDefinition<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> =
  TextExpansionCellDefinition<ObjectType, DefaultSelectorContainer>
  | ComponentExpansionCellDefinition<ObjectType, DefaultSelectorContainer>;

export interface TextExpansionCellDefinition<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> {
  type: 'text',

  /**
   * REQUIRED.  The literal string, selector creator name, or selector creator that represents the name for this row.  Note that this usually *WILL* be a
   * literal string, although there are times when the header's text is a variable value based on this row's object.
   *
   * If it is a string, it is assumed to be the name of a selector creator found in `defaultSelectorContainer`, or just a literal string if there is no such
   * selector creator.  Otherwise, it's a direct reference to an existing selector creator, which could be local or found inside some other selector namespace.
   */
  header: string | MxSelectorCreatorNameMatching<ObjectType, DefaultSelectorContainer> | AnyMxSelectorCreatorMatching<ObjectType>;

  /**
   * REQUIRED.  The literal string, selector creator name, or selector creator that represents the value for this row.  Note that this usually *WON'T* be a
   * literal string, because it is much more likely to be a variable value based on this row's object.
   *
   * If it is a string, it is assumed to be the name of a selector creator found in `defaultSelectorContainer`, or just a literal string if there is no such
   * selector creator.  Otherwise, it's a direct reference to an existing selector creator, which could be local or found inside some other selector namespace.
   */
  value: string | MxSelectorCreatorNameMatching<ObjectType, DefaultSelectorContainer> | AnyMxSelectorCreatorMatching<ObjectType>;

  /**
   * OPTIONAL.  Defaults to `'NONE'`, which means to display expansion values in this column as-is.  `FormatPipe` will be invoked with the format(s) specified
   * here.
   */
  format?: OneOrArrayOf<Format>;
}

export interface ComponentExpansionCellDefinition<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> {
  type: 'component',

  /**
   * REQUIRED.  The literal string, selector creator name, or selector creator that represents the name for this row.  Note that this usually *WILL* be a
   * literal string, although there are times when the header's text is a variable value based on this row's object.
   *
   * If it is a string, it is assumed to be the name of a selector creator found in `defaultSelectorContainer`, or just a literal string if there is no such
   * selector creator.  Otherwise, it's a direct reference to an existing selector creator, which could be local or found inside some other selector namespace.
   */
  header: string | MxSelectorCreatorNameMatching<ObjectType, DefaultSelectorContainer> | AnyMxSelectorCreatorMatching<ObjectType>;

  /** REQUIRED.  The type of the component to use when displaying the value of this expansion cell's value. */
  componentClass: Type<any>; // eslint-disable-line @typescript-eslint/no-explicit-any -- Generic component typing is difficult, maybe impossible.

  /** OPTIONAL.  Values that need to be set on the component. */
  componentProperties?: CellComponentProperties<ObjectType, DefaultSelectorContainer>;
}

export interface HiddenDataIndicatorRowDefinition<DefaultSelectorContainer extends LoadableSelectorContainer> {
  /**
   * REQUIRED.  A boolean selector name or boolean selector.  If the value is `true`, then a special row is shown at the end of the table to
   * indicate that there is hidden data available to be shown.  If the value is `false`, the special row is not shown.
   *
   * Presumably, this uses a selector that returns `true` when there is hidden data, and the table's data source includes a subset of the
   * available data. When the user clicks the action button in this row, then this selector returns `false`, and the table's data source includes all
   * the available data.
   *
   * If it is a string, it is assumed to be the name of a selector found in `defaultSelectorContainer`.  Otherwise, it's a direct reference to an existing
   * selector, which could be local or found inside some other selector namespace.
   */
  showIf: MxSelectorNameMatching<boolean, DefaultSelectorContainer> | MxSelector<boolean>;

  /** REQUIRED.  When the special row is shown, this is the message that is displayed.  It should indicate that there is hidden data available to be
   *  displayed.
   *
   * If it is a string, it is assumed to be the name of a selector found in `defaultSelectorContainer`, or just a literal string if there is no such
   * selector.  Otherwise, it's a direct reference to an existing selector, which could be local or found inside some other selector namespace.
   */
  message: string | MxSelectorNameMatching<string, DefaultSelectorContainer> | MxSelector<string>;

  /** OPTIONAL.  When the special row is shown, this is the message that is displayed if there is no other data to display.  This is here because the
   *  context of the "no data" message might be different, depending on whether there is no unhidden data to be shown vs. no data at all.
   */
  overriddenNoDataRowMessageWhenShowing?: string;

  /**
   * OPTIONAL.  Defaults to `'Show'`.
   *
   * If it is a string, it is assumed to be the name of a selector found in `defaultSelectorContainer`, or just a literal string if there is no such selector.
   * Otherwise, it's a direct reference to an existing selector, which could be local or found inside some other selector namespace.   */
  actionButtonLabel?: string | MxSelectorNameMatching<string, DefaultSelectorContainer> | MxSelector<string>;

  /**
   * OPTIONAL.  Defaults to `undefined`.  If this is a defined action function, the button's `(click)` handler is a function that creates and
   * dispatches the indicated action based on the row object.
   */
  clickActionCreator?: NoArgumentActionCreator;

  /**
   * OPTIONAL.  Defaults to `undefined`.  Ignored when `clickActionCreator` is defined.  If this is a defined function, it is used as the button's `(click)`
   * handler. The row object is sent to this handler.
   */
  clickHandler?: NoArgumentClickHandler;
}
