import {NgModule} from '@angular/core';
import {RegistrationEmailFormComponent} from './email-form/registration-email-form.component';
import {CommonModule} from '@angular/common';
import {CreateAccountComponent} from './create-account.component';
import {LookupAccountComponent} from './lookup-account.component';
import {RegistrationVerifyEmailFormComponent} from './verify-email-form/registration-verify-email-form.component';
import {RegistrationLookupAccountFormComponent} from './lookup-account-form/registration-lookup-account-form.component';
import {RegistrationNotificationsFormComponent} from './notifications-form/registration-notifications-form.component';
import {RegistrationDetailsFormComponent} from './details-form/registration-details-form.component';
import {RegistrationCreateAccountFormComponent} from './create-account-form/registration-create-account-form.component';
import {RegistrationCreatePasswordFormComponent} from './create-password/registration-create-password-form.component';
import {RegistrationCompleteComponent} from './registration-complete/registration-complete.component';
import {RegistrationAgeValidationComponent} from './registration-age-validation.component';
import {TermsComponent} from './terms.component';
import {SharedModule} from '../shared/shared.module';
import {RvFormsModule} from '../shared/form-fields/rv-forms.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RvFormsModule,
  ],
  declarations: [
    CreateAccountComponent,
    LookupAccountComponent,
    RegistrationCreateAccountFormComponent,
    RegistrationLookupAccountFormComponent,
    RegistrationEmailFormComponent,
    RegistrationVerifyEmailFormComponent,
    RegistrationDetailsFormComponent,
    RegistrationNotificationsFormComponent,
    RegistrationCreatePasswordFormComponent,
    RegistrationCompleteComponent,
    RegistrationAgeValidationComponent,
    TermsComponent,
  ],
  providers: [],
})
export class RegistrationModule {
}
