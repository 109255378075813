import {AbstractControl, FormControlOptions, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Observable} from 'rxjs';
import {isArray, isNull, isNumber, isObject} from './typing.helpers';
import {isPaginationInfo, PaginationInfo} from '@store/pagination/pagination.types';

export type LoadStatus = 'PRELOAD' | 'LOADING' | 'RELOADING' | 'LOADED' | 'FAILED';

export const PRELOAD = 'PRELOAD' as const;
export const LOADING = 'LOADING' as const;
export const RELOADING = 'RELOADING' as const;
export const LOADED = 'LOADED' as const;
export const FAILED = 'FAILED' as const;

export interface ResponseBase<T> {
  readonly errors?: ResponseBaseError[];
  readonly actions?: ResponseBaseAction[];
  // readonly alerts?: ResponseBaseAlert[];
  readonly objects: T[];
  readonly facets?: ResponseBaseFacets;
  readonly page: number;
  readonly limit: number;
  readonly totalCount: number;
}

export function isResponseBase<T = unknown>(thing: unknown): thing is ResponseBase<T> {
  return isObject(thing) && (isNull(thing.objects) || isArray(thing.objects)) && isNumber(thing.page) && isNumber(thing.limit) && isNumber(thing.totalCount);
}

export interface ResponseBaseError {
  readonly code: string;
  readonly field: string;
  readonly entityId: number;
  readonly message: string;
}

export interface ResponseBaseAction {
  readonly type: string;
  readonly data: unknown;
}

export interface ResponseBaseFacetValue {
  readonly displayValue: string;
  readonly filterValue: string;
}

export interface ResponseBaseFacet {
  values: ResponseBaseFacetValue[];
}

export interface ResponseBaseFacets {
  readonly [name: string]: ResponseBaseFacet;
}

export interface ListData<T> {
  readonly data: T[];
  readonly filterData: ListFilterData;
  readonly paginationInfo?: PaginationInfo;
}

export function isListData(thing: unknown): thing is ListData<unknown> {
  return isObject(thing) && (isArray(thing.data) || isNull(thing.data)) && isPaginationInfo(thing.paginationInfo);
}

export const EMPTY_LIST_DATA: ListData<never> = {
  data: [],
  filterData: {}
};

export interface ListFilterData {
  readonly [filterName: string]: ListFilterOptions;
}

export interface ListFilterOption {
  displayValue: string;
  filterValue: string;
}

export type ListFilterOptions = ListFilterOption[];

export interface FacetExtractor {
  addProperty: string,
  fromProperty: string
  facetName: string,
}

export type FacetExtractors = FacetExtractor[];
export type DateTimeIsoString = string;
export type DateTimeIsoWithMillisecondsString = string;
export type DateOnlyIsoString = string;
export type DateOnlyRangeIsoString = string;

export type DateString = DateTimeIsoString | DateOnlyIsoString;

export type DateRangeObject = {
  start?: Date;
  end?: Date;
}

export type SelectOptions<ValueType> = SelectOption<ValueType>[];

export interface SelectOption<ValueType> {
  displayName: string;
  value: ValueType;
}

export interface AddressType {
  readonly id: number;
  readonly description: string;
}

const US_STATES = [
  // U.S. states and territories
  'AE', 'AA', 'AL', 'AK', 'AS', 'AZ', 'AP', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS'
  , 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA'
  , 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY', 'VI'
] as const;

const CANADIAN_PROVINCES = [
  // Canadian provinces
  'ON', 'QC', 'NS', 'NB', 'MB', 'BC', 'PE', 'SK', 'AB', 'NL'
] as const;

const ALL_STATES = [
  ...US_STATES,
  ...CANADIAN_PROVINCES,
  ''
] as const;
export type State = (typeof ALL_STATES)[number];
export const STATE_SELECT: SelectOptions<string> =
  US_STATES.map(value => ({
    displayName: value, value
  }));

export const RV_STANDARD_ANIMATION_DURATION_MILLISECONDS = 225;
export const RV_STANDARD_ANIMATION_TIMINGS = `${RV_STANDARD_ANIMATION_DURATION_MILLISECONDS}ms ease-in-out`;

export type AsyncValidateFunction = (control: AbstractControl) => Observable<ValidationErrors | null>;

export type ObjectValidators<ObjectType extends object> = {
  [property in keyof ObjectType]-?: ValidatorFn | ValidatorFn[] | FormControlOptions | ObjectValidators<ObjectType[property] & object>;
};
export const NO_OP = () => {
  // This function is empty because no-op functions don't do anything, by definition. (This comment prevents a SonarLint complaint.)
};
export type StatusChipColor = 'status-positive' | 'status-neutral' | 'status-negative' | 'status-transitional' | 'status-inactive';

export type StatusChipColors<T> = {
  readonly [color in StatusChipColor]: T[];
};
export const DEFAULT_EMPTY_VALUE_REPLACEMENT_STRING: string = '--';
