<rv-base-form-field [fieldName]="fieldName" [overrideLabel]="overrideLabel" [externalLabel]="externalLabel" [formGroup]="formGroup" [control]="control"
                    [focused]="focused" [enabled]="enabled" [hintLabel]="hintLabel" [errorLabel]="errorLabel" [subscriptSizing]="subscriptSizing"
                    [customError]="customError">
  <mat-icon *ngIf="includeSearchIcon" class="mat-icon-size-6 me-3" [ngClass]="enabled ? 'text-surface-contrast' : 'text-disabled'">search</mat-icon>

  <span *ngIf="includeDollarSign" class="text-label-large me-3" [ngClass]="enabled ? 'text-surface-contrast' : 'text-disabled'">$</span>

  <input matInput [type]="inputType" [formControl]="control" [placeholder]="placeholder"
         (focus)="onFocusInput()" (blur)="onBlurInput()" (keydown.enter)="onKeydownEnter()"
         [class.text-right]="alignRight"/>

  <span *ngIf="postfixText">{{ postfixText }}</span>

  <button mat-icon-button *ngIf="showClearButton" type="button" (click)="onClickClearButton()">
    <mat-spinner *ngIf="replaceClearButtonWithSpinner | select; else icon" [diameter]="24"/>

    <ng-template #icon>
      <mat-icon>clear</mat-icon>
    </ng-template>
  </button>

  <button mat-icon-button *ngIf="showAuxiliaryButton" type="button" (click)="onClickAuxiliaryButton()">
    <mat-icon [class]="auxiliaryButtonIconClass">{{ auxiliaryButtonIconName }}</mat-icon>
  </button>
</rv-base-form-field>
