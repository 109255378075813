import {Checkout, CheckoutClaim} from '@store/checkouts/checkouts.types';

export const FALLBACK_CHECKOUT: Checkout = {
  checkoutId: 0,
  catalogRecordId: 0,
  checkoutBranchName: '',
  checkoutTime: '',
  claimId: 0,
  claimType: '',
  dueDate: '',
  dueDays: 0,
  foundDate: '',
  holdExists: false,
  itemBarcode: '',
  itemMediaType: '',
  itemTitle: '',
  lastRenewal: '',
  patronFirstName: '',
  patronLastName: '',
  status: 'DUE',
  imageUrl: '',
}

export const FALLBACK_CHECKOUT_CLAIM: CheckoutClaim = {
  id: 0,
  checkoutId: 0,
  patronId: 0,
  patronComment: '',
  staffComment: '',
  type: 'CLAIMED_RETURNED',
  status: 'UNRESOLVED',
  searchAttempts: 0,
  lastSearchDate: '',
  createdByUserId: 0,
  modifiedByUserId: 0,
  active: false,
  created: '',
  modified: '',

  checkout: FALLBACK_CHECKOUT,
}
