import {Directive, inject, Input, ViewContainerRef} from '@angular/core';

export interface DynamicComponentHostDirectiveData {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any -- This is a POJO.
}

@Directive({
  selector: 'ng-template[rvDynamicComponentHost]'
})
/**
 * This directive exists solely to attach a `ViewContainerRef` to some element so that a dynamically generated component can be rendered there.
 *
 * It also accepts an optional `data` input into which any needed data can be stashed for later retrieval.
 */
export class DynamicComponentHostDirective<DataType extends DynamicComponentHostDirectiveData = object> {
  @Input() data: DataType = {} as DataType;

  public readonly viewContainer: ViewContainerRef = inject(ViewContainerRef);
}
