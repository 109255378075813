import {createReducer, on} from '@ngrx/store';
import {Params} from '@angular/router';
import {QueryParamsActions} from '@store/query-params/query-params.actions';

export interface QueryParamsState {
  [routeName: string]: Params;
}

export const INITIAL_QUERY_PARAMS_STATE: QueryParamsState = {};

export const queryParamsState = createReducer(
  INITIAL_QUERY_PARAMS_STATE,
  on(QueryParamsActions.save, (state, {routeName, queryParams}) => ({
    ...state,
    [routeName]: queryParams
  })),
  on(QueryParamsActions.clear, (state, {routeName}) => ({
    ...state,
    [routeName]: {}
  }))
);
