import {Pipe, PipeTransform} from '@angular/core';
import {isPopulatedString} from '@store/common/typing.helpers';
import {DEFAULT_EMPTY_VALUE_REPLACEMENT_STRING} from '@store/common/common.types';

@Pipe({
  name: 'replaceEmpty'
})
export class ReplaceEmptyPipe implements PipeTransform {
  transform(value: string | null | undefined, replacementValue: string = DEFAULT_EMPTY_VALUE_REPLACEMENT_STRING): string {
    return isPopulatedString(value) ? value : replacementValue;
  }
}
