import {AfterViewInit, Directive, ElementRef, inject, Input} from '@angular/core';

@Directive({
  selector: '[rnHiddenUntilLoaded]'
})
export class HiddenUntilLoadedDirective implements AfterViewInit {

  @Input()
  public set reveal(reveal: boolean) {
    this.elementClassList[reveal ? 'add' : 'remove']('hk-reveal');
  }

  private readonly elementClassList: DOMTokenList = (inject(ElementRef).nativeElement as Element).classList;

  public ngAfterViewInit(): void {
    this.elementClassList.add('hk-hidden-until-loaded');
  }
}
