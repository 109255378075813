import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, OperatorFunction, pipe, tap} from 'rxjs';
import {RouterActions} from '@store/router/router.actions';
import {withLatestFromSelectors} from '@store/common/effects.helpers';
import {RouterSelectors} from '@store/store.selectors';
import {Params, Router} from '@angular/router';
import {omitPropertiesByKeyFrom} from '@store/transformation.helpers';
import {PATH_FOR} from '../../app.routes';
import {buildValidatedRouterPathFrom} from '@store/router/router.helpers';
import {asArray, isDefined} from '@store/common/typing.helpers';
import {MatDialog} from '@angular/material/dialog';
import {Action, Store} from '@ngrx/store';
import {ROUTER_NAVIGATED} from '@ngrx/router-store';
import {DEFAULT_PAGINATION_INFO} from '@store/pagination/pagination.types';

@Injectable({providedIn: 'root'})
export class RouterEffects {

  private readonly actions = inject(Actions);
  private readonly router = inject(Router);
  private readonly matDialog = inject(MatDialog);
  private readonly store: Store = inject(Store);

  public navigateTo = createEffect(
    () => this.actions.pipe(
      ofType(RouterActions.navigateTo),
      tap(({routeName, params}) => {
        this.router.navigate([buildValidatedRouterPathFrom(routeName, params)]).then();
      })
    ),
    {dispatch: false}
  );

  public replaceRouteWith = createEffect(
    () => this.actions.pipe(
      ofType(RouterActions.replaceRouteWith),
      tap(({routeName, params}) => {
        this.router.navigate([buildValidatedRouterPathFrom(routeName, params)], {replaceUrl: true}).then();
      })
    ),
    {dispatch: false}
  );

  public updateSearchParams = createEffect(
    () => this.actions.pipe(
      ofType(RouterActions.updateSearchParams),
      map(({query, queryType, destinationRouteName}) => ({
        destinationPath: PATH_FOR[destinationRouteName], // Assumption: A route path used here will never have variable parameters to substitute.
        newQueryParams: {
          ...query && queryType && {qType: queryType},
          ...query && {q: query}
        },
        paramNamesToRemove: ['qType', 'q', 'page'] // Start any new search on the first page.
      })),
      this.navigate()
    ),
    {dispatch: false}
  );

  public updatePaginationParams = createEffect(
    () => this.actions.pipe(
      ofType(RouterActions.updatePaginationParams),
      withLatestFromSelectors(RouterSelectors.queryParamValueAsNumber('pageSize')),
      map(([{page, pageSize}, previousPageSize]) => {
        // Reset to the first page when the page size has changed.  Account for the fact that pageSize is omitted from parameters when it's the default value.
        const newPage: number =
          pageSize === (previousPageSize ?? DEFAULT_PAGINATION_INFO.pageSize)
            ? page
            : 1;

        return {
          newQueryParams: {
            ...newPage > DEFAULT_PAGINATION_INFO.page && {page: newPage},
            ...pageSize !== DEFAULT_PAGINATION_INFO.pageSize && {pageSize}
          },
          paramNamesToRemove: ['page', 'pageSize']
        };
      }),
      this.navigate()
    ),
    {dispatch: false}
  );

  public updateSortParams = createEffect(
    () => this.actions.pipe(
      ofType(RouterActions.updateSortParams),
      map(({angularSortState, entityId}) => {
        const parameterName: string = isDefined(entityId) ? `sort-${entityId}` : 'sort';

        return {
          newQueryParams: {
            ...angularSortState.direction && {
              [parameterName]: angularSortState.direction === 'asc' ? angularSortState.active : `${angularSortState.active},desc`
            }
          },
          paramNamesToRemove: [parameterName, 'page'] // Reset to the first page on any sorting change.
        };
      }),
      this.navigate()
    ),
    {dispatch: false}
  );

  public addFilterParam = createEffect(
    () => this.actions.pipe(
      ofType(RouterActions.addFilterParam),
      map(({name, value}) => ({
        newQueryParams: {
          [name]: value
        },
        /*
          If this filter was already present, clear it so that we update/replace it.  If it wasn't, then clearing it is a no-op anyway.
          Also, reset to the first page on any filtering change (including "search").
        */
        paramNamesToRemove: [name, 'page']
      })),
      this.navigate()
    ),
    {dispatch: false}
  );

  public removeFilterParam = createEffect(
    () => this.actions.pipe(
      ofType(RouterActions.removeFilterParam),
      map(({name}) => ({
        newQueryParams: {},
        paramNamesToRemove: [name, 'page'] // Reset to the first page on any filtering change (including "search").
      })),
      this.navigate()
    ),
    {dispatch: false}
  );

  public removeQueryParams = createEffect(
    () => this.actions.pipe(
      ofType(RouterActions.removeQueryParams),
      map(({names}) => ({
        newQueryParams: {},
        paramNamesToRemove: asArray(names)
      })),
      this.navigate()
    ),
    {dispatch: false}
  );

  public closeModals = createEffect(
    () => this.actions.pipe(
      ofType<Action>(ROUTER_NAVIGATED),
      map(() => this.matDialog.closeAll())
    ),
    {dispatch: false}
  );

  private navigate(): OperatorFunction<{ destinationPath?: string; newQueryParams: Params; paramNamesToRemove: string[] }, unknown> {
    return pipe(
      withLatestFromSelectors(RouterSelectors.path, RouterSelectors.queryParams),
      tap(([{destinationPath, newQueryParams, paramNamesToRemove}, currentPath, currentQueryParams]) =>
        this.router.navigate(
          [destinationPath ?? currentPath],
          {
            queryParams: this.arrangeInPreferredOrder({
              ...newQueryParams,
              ...omitPropertiesByKeyFrom(currentQueryParams, ...paramNamesToRemove)
            })
          }
        ).then()
      )
    );
  }

  private arrangeInPreferredOrder({qType, q, sort, page, pageSize, ...otherFilters}: Params): Params {
    return {qType, q, sort, page, pageSize, ...otherFilters};
  }
}
