import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {RoutesService} from '@raven';

@Component({
  selector: 'rn-combined-cover-title-barcode',
  templateUrl: './combined-cover-title-barcode.component.html',
  styles: [`
      a {
          line-height: 1.25rem
      }

      .item {
          max-height: 2.5rem;
          max-width: 3rem;
      }

      .item-img {
          width: 100%;
          aspect-ratio: 180 / 277;
          margin-bottom: 6px;
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CombinedCoverTitleBarcodeComponent {
  @Input() public titleText?: string;
  @Input() public barcode?: string;
  @Input() public catalogRecordId?: number;
  @Input() public imageUrl?: string;
  public readonly routingService = inject(RoutesService);
}
