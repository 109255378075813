<div class="page-body-wrapper">
  <rn-account-status-warning context="banner"/>
  <div class="flex-row">
    <div class="my-account-nav">
      <div class="mat-headline-4">My Account</div>
      <a class="my-account-nav-link" routerLink="/dashboard" routerLinkActive="active-route">
        <span>Dashboard</span>
        <mat-icon>arrow_forward</mat-icon>
      </a>
      <mat-divider/>
      <a class="my-account-nav-link" routerLink="/checkouts" routerLinkActive="active-route">
        <span>Checked Out</span>
        <mat-icon>arrow_forward</mat-icon>
      </a>
      <mat-divider/>
      <a class="my-account-nav-link" routerLink="/holds" routerLinkActive="active-route">
        <span>On Hold</span>
        <mat-icon>arrow_forward</mat-icon>
      </a>
      <mat-divider/>
      <a class="my-account-nav-link" routerLink="/profile#billing" routerLinkActive="active-route">
        <span>Balance</span>
        <mat-icon>arrow_forward</mat-icon>
      </a>
      <mat-divider/>
      <a class="my-account-nav-link" routerLink="/patron-item-requests-v2" routerLinkActive="active-route">
        <span>Requests</span>
        <mat-icon>arrow_forward</mat-icon>
      </a>
      <mat-divider/>
      <a class="my-account-nav-link" routerLink="/profile?fragment=account" routerLinkActive="active-route">
        <span>My Profile</span>
        <mat-icon>arrow_forward</mat-icon>
      </a>
      <mat-divider/>
      <a class="my-account-nav-link" routerLink="/favorites" routerLinkActive="active-route">
        <span>Favorites</span>
        <mat-icon>arrow_forward</mat-icon>
      </a>
      <mat-divider/>
      <a class="my-account-nav-link">
        <span>Settings</span>
        <mat-icon>arrow_forward</mat-icon>
      </a>
    </div>
    <div class="checkouts-content">
      <div class="checkouts-header flex-row flex-center-space-between">
        <div class="mat-headline-4">Checked Out</div>
        <ng-container *ngIf="{
          noBlocks: accountStatusSelector.noBlocks | select,
          allCheckouts: selectors.checkouts | select,
        } as vm">
          <button (click)="renewCheckout(vm.allCheckouts, vm.noBlocks)" [disabled]="!hasRenewableItems(vm.allCheckouts, vm.noBlocks)"
                  class="ButtonSmallBlack save-button-group save-button"
                  color="primary" mat-raised-button>
            Renew All
          </button>
        </ng-container>
      </div>
      <ng-container *ngIf="isMobile">
        <div class="mobile-table">
          <div *ngFor="let checkout of selectors.checkouts | select:[]; let isLast = last; trackBy: trackCheckoutsById">
            <div class="flex-row flex-start-space-between">
              <div class="mobile-table-entry flex-col">
                <rn-combined-cover-title-barcode [titleText]="selectors.itemTitleFor(checkout) | select"
                                                 [barcode]="selectors.itemBarcodeFor(checkout) | select"
                                                 [catalogRecordId]="selectors.catalogRecordIdFor | select"/>
                <div class="mat-caption">Due Date:
                  <span [class.text-error]="selectors.overDueFor | select">{{ selectors.dueDateFor(checkout) | select | format: 'DATE_ONLY' }}</span>
                </div>
                <div class="mat-caption">Checkout Date: {{ selectors.checkoutTimeFor(checkout) | select | format: 'DATE_ONLY' }}</div>
              </div>
              <rn-patron-checkouts-actions [checkout]="checkout"/>
            </div>
            <mat-divider class="mobile-table-divider" *ngIf="!isLast"/>
          </div>
          <rn-pagination [class.loaded]="!selectors.awaitingAnyLoad"/>
        </div>
      </ng-container>
      <div [class.hidden]="isMobile">
        <rn-table [tableDefinition]="checkoutsTableDefinition"/>
      </div>
      <div class="flex-row flex-start-space-between">
        <div>
          <div class="mat-subtitle-2">Can't find what you're looking for?</div>
          <div>Maybe it's in the ILL! <a href="https://elibrary.mel.org/" target="_blank" rel="noopener">Check MeLCat</a></div>
        </div>
        <a class="flex-row flex-center-center">View Borrowing History
          <mat-icon>arrow_forward</mat-icon>
        </a>
      </div>

      <div class="claims-header">
        <div class="mat-headline-4">Claims ({{ claimsSelectors.numberOfClaims | select }})</div>
      </div>
      <ng-container *ngIf="isMobile">
        <div class="mobile-table">
          <div *ngFor="let claim of claimsSelectors.claims | select:[]; let isLast = last; trackBy: trackClaimsById">
            <div class="flex-row flex-start-space-between">
              <div class="mobile-table-entry flex-col">
                <rn-combined-cover-title-barcode [titleText]="claimsSelectors.itemTitleFor(claim) | select"
                                                 [barcode]="claimsSelectors.itemBarcodeFor(claim) | select"
                                                 [catalogRecordId]="claimsSelectors.catalogRecordIdFor(claim) | select"/>
                <div class="mat-caption">Submitted: {{ claimsSelectors.createdFor(claim) | select | format: 'DATE_ONLY' }}</div>
              </div>
            </div>
            <mat-divider class="mobile-table-divider" *ngIf="!isLast"/>
          </div>
        </div>
      </ng-container>
      <div [class.hidden]="isMobile">
        <rn-table [tableDefinition]="claimsTableDefinition"/>
      </div>
    </div>
  </div>
</div>

