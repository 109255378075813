import {HttpParams} from '@angular/common/http';
import {Params} from '@angular/router';
import {SortDirection} from '@angular/material/sort';
import {FacetExtractors, ListData, ListFilterData, ResponseBase, ResponseBaseFacets} from '@store/common/common.types';
import {hasValue, isNumber, isPopulatedArray, isString, isUndefined} from '@store/common/typing.helpers';
import {DEFAULT_PAGINATION_INFO} from '@store/pagination/pagination.types';
import {extractPropertiesByKeyFrom, omitPropertiesByKeyFrom} from '@store/transformation.helpers';
import {OneOrArrayOf} from '@store/utility-type.helpers';

export interface FetchParams {
  filters: FiltersState,
  sort?: SortState,
  pagination: PaginationState,
}

export interface FiltersState {
  [key: string]: unknown;
}

export interface PaginationState {
  page: number;
  pageSize: number;
}

export interface SortState {
  sortBy: string;
  sortDirection: SortDirection;
}

export type MappableKey = keyof SortState | 'sort' | keyof PaginationState | 'q' | 'qType' | 'search' | 'filters';
export type KeyMap = { [key in MappableKey]?: string };
export type OmissibleSection = 'FILTERS' | 'SORT' | 'PAGINATION';
export type ApiFilters = { [key: string]: OneOrArrayOf<string>; };
export type ValueDecoder<T extends OneOrArrayOf<string>> = (queryParamValue: string) => T;
export type QueryValueMapper<T extends OneOrArrayOf<string>> = (queryParamValue: T) => T;
export type QueryValueQualifier<T extends OneOrArrayOf<string>> = (queryParamValue: T) => boolean;
export type SplitMapper<T extends OneOrArrayOf<string>> = (queryParamValue: T) => ApiFilters;

export const AS_IS = 1;  // Arbitrary value -- just something that doesn't clash with the string type in the ApiFilterNameMapper union type.
export type ApiFilterNameMapper = string | typeof AS_IS;

export interface QueryParamMapper<T extends OneOrArrayOf<string>> {
  /** OPTIONAL.  Ignored when `splitMapper` is defined.  Use this to specify the API's version of this filter's name if it differs from the UI's query parameter
   *  name.  Use the special value `AS_IS` when you want to send this filter to the API without changes (which is necessary for mapping to array filters,
   *  since those are sent to the server only when defined in `options.mapArrayFilters`).
   */
  apiName?: ApiFilterNameMapper;

  /** OPTIONAL.  Use this to prevent query parameters with bad values from being sent to the API.  If this function returns `true`, the filter will be
   *  mapped as specified and sent to the API.  Otherwise, it will be discarded.  (You can think of this like a `filter()` function as used in arrays or
   *  observables. The name 'filter' was intentionally avoided here to prevent confusion since this object is relevant to search filter functionality.)
   */
  sendOnlyIf?: QueryValueQualifier<T>;

  /** OPTIONAL. Ignored when `splitMapper` is defined.  Use this to map the filter's value to some other value. */
  valueMapper?: QueryValueMapper<T>;

  /** OPTIONAL.  Use this to completely replace the filter with a different set of two or more filters.  Note that if you're going to end up with just one
   * mapped filter, then you should prefer the standard `apiName` and/or `valueMapper` properties instead.
   */
  splitMapper?: SplitMapper<T>;
}

export type FilterMapValue<T extends OneOrArrayOf<string>> = ApiFilterNameMapper | QueryParamMapper<T>;

/**
 * Defines a mapping from filters defined in UI query parameters to filters sent to the API.  The key is the UI query parameter's name.  The value can be as
 * simple as a string or 'AS_IS' (see `apiName` in `QueryParamMapper`).  If you need any different or additional filter mapping, specify a `QueryParamMapper`
 * object for the value instead.
 */
export interface FilterMap<T extends OneOrArrayOf<string>> {
  [queryParamName: string]: FilterMapValue<T>
}

// NOTE: If you update this, also update the jsdoc comment for OutputOptions.map!  (Or figure out how to link these so they're DRY...)
export const DEFAULT_MAP: Required<KeyMap> = {
  q: 'query',
  qType: 'queryType',
  search: 'query',
  filters: 'filters',
  sortBy: 'sortBy',
  sortDirection: 'direction',
  sort: 'sort',
  page: 'page',
  pageSize: 'size'
}

export interface OutputOptions {
  /**
   * Use this for endpoints that use different key names for parameters.  For example:
   * <pre>
   *   map: { pageSize: 'limit' }
   * </pre>
   * Default mappings:
   * <pre>
   *   q: 'query',
   *   qType: 'queryType',
   *   search: 'query',
   *   filters: 'filters',
   *   sortBy: 'sortBy',
   *   sortDirection: 'direction',
   *   sort: 'sort',
   *   page: 'page',
   *   pageSize: 'size'
   * </pre>
   */
  map?: KeyMap;

  /**
   * Use this to omit certain sets of parameters from the generated parameter list.  For example:
   * <pre>
   *   omit: ['FILTERS', 'SORT']`
   * </pre>
   * By default, no parameters are omitted.
   */
  omit?: OmissibleSection[];

  /**
   * `separateSortParameters: true` will generate parameters like:
   * <pre>
   *   { sortBy: 'fieldName', sortDirection: 'asc' }
   * </pre>
   * `separateSortParameters: false` will generate a single parameter like:
   * <pre>
   *   { sort: 'fieldName,asc' }
   * </pre>
   * <ul>
   *   <li>Defaults to <code>false</code>.</li>
   *   <li>You can also use the <code>map</code> option to change the generated key names.</li>
   *   <li>This option has no effect when the <code>omit</code> option contains <code>'SORT'</code>.</li>
   * </ul>
   */
  separateSortParameters?: boolean;

  /**
   * `wrapFilters: true` will wrap any filters (all non-sort and non-pagination parameters) in a `filters: {}` parameter.
   *
   * `wrapFilters: false` will keep filters at the top level of the generated parameters object.
   *
   * Defaults to `false`.
   */
  wrapFilters?: boolean;

  /**
   * `wrapSearchFilters: true` will include search filters ('q' and 'qType') in the `filters: {}` parameter.
   *
   * `wrapSearchFilters: false` will keep search filters at the top level of the generated parameters object.
   * <ul>
   *   <li>Defaults to <code>true</code>.</li>
   *   <li>You can also use the <code>map</code> option to change the generated key names for search filters.</li>
   *   <li>This option has no effect when the <code>wrapFilters</code> option is <code>false</code>.</li>
   * </ul>
   */
  wrapSearchFilters?: boolean;

  /**
   * Use this when the query parameter names and/or values are not the same as what the API endpoint expects.  Values for filters specified here will be
   * sent to the API as single values or comma-delimited strings.  See the `FilterMap` type.
   */
  mapFilters?: FilterMap<string>;

  /**
   * Same as `mapFilters`, except that values for filters specified here will be sent to the API as single values or arrays of strings (instead of
   * comma-delimited strings).
   */
  mapArrayFilters?: FilterMap<string[]>;
}

export function buildBodyFrom(params: FetchParams | Params, options: OutputOptions = {}): object {
  // Eventually, we can refactor this function to work directly with query params, but for now...
  // FetchParams should be refactored to take only string values
  const fetchParams: FetchParams = areFetchParams(params) ? params : convertToFetchParams(params);

  const generatedKeys: Required<KeyMap> = {
    ...DEFAULT_MAP,
    ...options.map
  };

  return {
    ...!options.omit?.includes('FILTERS') && buildFilterParametersFrom(fetchParams.filters, options, generatedKeys),
    ...!options.omit?.includes('SORT') && fetchParams.sort && buildSortParametersFrom(fetchParams.sort, options, generatedKeys),
    ...!options.omit?.includes('PAGINATION') && buildPaginationParametersFrom(fetchParams.pagination, generatedKeys),
  };
}

export function buildQueryParamsFrom(params: FetchParams | Params, options: OutputOptions = {}): HttpParams {
  return new HttpParams({fromObject: {...buildBodyFrom(params, options)}});
}

export function extractListData<T>(
  {objects: data, page, limit: pageSize, totalCount, facets}: ResponseBase<T>,
  facetExtractors: FacetExtractors = [],
  includePaginationInfo: boolean = true
): ListData<T> {
  return {
    data: addFacetDataTo(data, facetExtractors, facets),
    filterData: extractFilterDataFrom(facets),
    ...includePaginationInfo && {
      paginationInfo: {
        page: page + 1,
        pageSize,
        totalCount
      }
    }
  };
}

//// Module-local functions

function buildFilterParametersFrom(filtersState: FiltersState, options: OutputOptions, generatedKeys: Required<KeyMap>): object {
  const searchFilters: ApiFilters = {
    // These two come from SearchComponent...
    ...'q' in filtersState && {[generatedKeys.q]: String(filtersState.q) || ''},
    ...'qType' in filtersState && {[generatedKeys.qType]: String(filtersState.qType) || ''},

    // ...and this one comes from TableCardTabComponent.
    ...'search' in filtersState && {[generatedKeys.search]: String(filtersState.search) || ''}
  };

  const arrayFilterProperties: FiltersState = extractPropertiesByKeyFrom(filtersState, ...Object.keys(options.mapArrayFilters ?? {}));
  const arrayFilterValueDecoder: ValueDecoder<string[]> = filterValue => filterValue.split(',').map(decodeURIComponent);
  const mappedArrayFilters: ApiFilters = mapFiltersFrom(arrayFilterProperties, options.mapArrayFilters, arrayFilterValueDecoder);

  const stringFilterProperties: FiltersState = omitPropertiesByKeyFrom(filtersState, 'q', 'qType', 'search', ...Object.keys(arrayFilterProperties));
  const stringFilterValueDecoder: ValueDecoder<string> = filterValue => arrayFilterValueDecoder(filterValue).join(',');
  const mappedStringFilters: ApiFilters = mapFiltersFrom(stringFilterProperties, options.mapFilters, stringFilterValueDecoder);

  const combinedNonSearchFilters: ApiFilters = {...mappedArrayFilters, ...mappedStringFilters};

  // If we ever get rid of wrapFilters/wrapSearchFilters, we can change this function's return type to ApiFilters and simply return this final combined object.
  const combinedFilters: ApiFilters = {...searchFilters, ...combinedNonSearchFilters};

  if (!options.wrapFilters) return combinedFilters;

  return options.wrapSearchFilters
    ? {[generatedKeys.filters]: combinedFilters}
    : {...searchFilters, [generatedKeys.filters]: combinedNonSearchFilters};
}

function mapFiltersFrom<T extends OneOrArrayOf<string>>(
  filtersState: FiltersState,
  filterMap: FilterMap<T> | undefined,
  valueDecoder: ValueDecoder<T>
): ApiFilters {
  return Object.entries(filtersState).reduce(
    (accumulator, [queryParamName, queryParamValue]) => {
      const decodedValue: T = valueDecoder(String(queryParamValue) || '');
      const mappedFilter: FilterMapValue<T> | undefined = filterMap?.[queryParamName];

      if (isString(mappedFilter)) {
        return {
          ...accumulator,
          [mappedFilter]: decodedValue
        };
      }

      if (isUndefined(mappedFilter) || mappedFilter === AS_IS) {
        return {
          ...accumulator,
          [queryParamName]: decodedValue
        };
      }

      if (mappedFilter.sendOnlyIf && !mappedFilter.sendOnlyIf(decodedValue)) {
        return accumulator;
      }

      if (mappedFilter.splitMapper) {
        return {
          ...accumulator,
          ...mappedFilter.splitMapper(decodedValue)
        };
      }

      return {
        ...accumulator,
        [mappedFilter.apiName ?? queryParamName]: mappedFilter.valueMapper ? mappedFilter.valueMapper(decodedValue) : decodedValue
      };
    },
    {}
  );
}

function buildSortParametersFrom(sortState: SortState, options: OutputOptions, generatedKeys: Required<KeyMap>): object {
  return options.separateSortParameters
    ? {
      [generatedKeys.sortBy]: sortState.sortBy,
      [generatedKeys.sortDirection]: sortState.sortDirection
    }
    : {
      [generatedKeys.sort]: `${sortState.sortBy},${sortState.sortDirection}`
    };
}

function buildPaginationParametersFrom(paginationState: PaginationState, generatedKeys: Required<KeyMap>): object {
  return {
    ...paginationState.page > 0 && {[generatedKeys.page]: paginationState.page},
    [generatedKeys.pageSize]: paginationState.pageSize // No consistent default size in the API yet, but we can suppress that future default here.
  };
}

function areFetchParams(params: FetchParams | Params): params is FetchParams {
  return 'filters' in params && 'pagination' in params;  // Slightly wishful thinking, but this function won't exist after we're finished with ListDataService.
}

function convertToFetchParams(params: Params): FetchParams {
  const urlParamAsSortState: SortState | undefined = createSortStateFrom(params.sort);

  const page: number | undefined = Number(params.page);
  const pageSize: number | undefined = Number(params.pageSize);

  const urlParamsAsPaginationState = {
    page: (isNumber(page) ? page : DEFAULT_PAGINATION_INFO.page) - 1, // We are 1-based, API is 0-based.
    pageSize: isNumber(pageSize) ? pageSize : DEFAULT_PAGINATION_INFO.pageSize
  };

  const filterParams: Params = omitPropertiesByKeyFrom(params, 'sort', 'page', 'pageSize');

  return {
    filters: filterParams,
    ...urlParamAsSortState && {sort: urlParamAsSortState},
    pagination: urlParamsAsPaginationState
  };
}

function createSortStateFrom(sortParameterValue?: string): SortState | undefined {
  if (!sortParameterValue) return undefined;

  const [sortBy, sortDirection] = sortParameterValue.split(',');

  return {
    sortBy,
    sortDirection: (sortDirection ?? 'asc') as SortDirection
  }
}

function addFacetDataTo<T>(data: T[], facetExtractors: FacetExtractors, facets?: ResponseBaseFacets): T[] {
  if (!facets || !isPopulatedArray(facetExtractors)) return data;

  return data.map(datum => ({
    ...datum,
    ...facetExtractors.reduce(
      (accumulator, extractor) => ({
        ...accumulator,
        [extractor.addProperty]: extractAddedPropertyValueFrom(facets, extractor.facetName, datum[extractor.fromProperty])
      }),
      {}
    )
  }));
}

function extractAddedPropertyValueFrom(facets: ResponseBaseFacets, facetName: string, fromProperty: unknown): string {
  return facets[facetName].values?.find(value => value.filterValue === String(fromProperty))?.displayValue ?? '';
}

function extractFilterDataFrom(facets?: ResponseBaseFacets): ListFilterData {
  if (!facets) return {};

  return Object.entries(facets)
    .filter(([, options]) => hasValue(options))
    .reduce<ListFilterData>(
      (accumulator, [filterName, {values}]) => ({
        ...accumulator,
        [filterName]: values.map(({displayValue, filterValue}) => ({displayValue, filterValue}))
      }),
      {}
    );
}
