import {createReducer, on} from '@ngrx/store';

import {PaginationActions} from '@store/pagination/pagination.actions';
import {DEFAULT_PAGINATION_INFO, PaginationInfo} from '@store/pagination/pagination.types';

export type PaginationState = PaginationInfo

export const paginationState = createReducer(
  DEFAULT_PAGINATION_INFO,
  on(PaginationActions.update, (_state, {info}) => info)
);
