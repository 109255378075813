import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, Input} from '@angular/core';
import {RowSelectionService} from '../row-selection.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ActionCreator} from '../../table.component.types';

@Component({
  selector: 'rn-all-rows-selection-checkbox',
  template: `
    <div [matTooltip]="tooltipText">
      <mat-checkbox color="primary" [checked]="allRowsAreSelected" [indeterminate]="someRowsAreSelected" [disabled]="noRowsAreSelectable"
                    (change)="onChangeCheckbox($event)"/>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllRowsSelectionCheckboxComponent<RowObject> implements AfterViewInit {
  @Input()
  public set data(data: RowObject[]) {
    this.rowSelectionService.data = data;
  }

  @Input()
  public set rowSelectionChangeAction(rowSelectionChangeAction: ActionCreator<RowObject[]> | undefined) {
    this.rowSelectionService.rowSelectionChangeAction = rowSelectionChangeAction;
  }

  public ngAfterViewInit(): void {
    const tableElement: HTMLTableElement | null = (this.elementReference.nativeElement as HTMLElement).closest('table');
    if (!tableElement) throw Error('AllRowsSelectionCheckboxComponent needs to be inside a <table> element');

    this.rowSelectionService.tableElement = tableElement;
  }

  public get allRowsAreSelected(): boolean {
    return this.rowSelectionService.allRowsAreSelected;
  }

  public get someRowsAreSelected(): boolean {
    return this.rowSelectionService.someRowsAreSelected;
  }

  public get noRowsAreSelectable(): boolean {
    return this.rowSelectionService.noRowsAreSelectable;
  }

  public get tooltipText(): string {
    if (this.noRowsAreSelectable) return 'There are no rows available to select.';
    if (this.allRowsAreSelected) return 'Deselect all';

    // Whether we have no selections or some selections at this point, the next click will select all.
    return this.rowSelectionService.allRowsAreSelectable ? 'Select all' : 'Select all available rows';
  }

  public onChangeCheckbox(event: MatCheckboxChange): void {
    if (event.checked) {
      this.rowSelectionService.selectAllRows();
    } else {
      this.rowSelectionService.deselectAllRows();
    }
  }

  private readonly elementReference: ElementRef = inject(ElementRef);
  private readonly rowSelectionService: RowSelectionService<RowObject> = inject(RowSelectionService);
}
