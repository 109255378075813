import {createFeatureSelector, createSelector, Store} from '@ngrx/store';
import {generateLoadStatusSelectorsFor, generatePersonNameSelectorsFrom, generateSelectorsDependentOn} from '@store/store.selector-generators';
import {MxSelector, MxSelectorCreator, NO_NAME_PREFIX} from '@store/store.types';
import {FALLBACK_PATRON, FALLBACK_PATRON_ACCOUNT_STATUS, FALLBACK_REGISTRATION_VALUE} from './patron.fallback';
import {PatronState} from './patron.state';
import {
  Patron,
  PATRON_FEATURE_KEY,
  PatronAccountEditValue,
  PatronAccountStatus,
  PatronRegistrationValue,
  REGISTRATION_CREATE_ACCOUNT_STEPS,
  REGISTRATION_LOOKUP_ACCOUNT_STEPS,
  RegistrationStep
} from './patron.types';
import {snapshot} from '@store/store.helpers';
import {isPopulatedString} from '@store/common/typing.helpers';
import {alwaysEmptyString} from '@store/common/common.selectors';

const entireState: MxSelector<PatronState> = createFeatureSelector(PATRON_FEATURE_KEY);

export namespace Auth {
  const subState: MxSelector<PatronState['auth']> = createSelector(
    entireState,
    state => state.auth
  );

  // LOAD STATUS
  export const {
    loading,
    awaitingFirstLoad,
    loaded,
    finishedLoading
  } = generateLoadStatusSelectorsFor(subState);

  // PATRON
  export const patron: MxSelector<Patron> = createSelector(
    subState,
    _subState => _subState.data ?? FALLBACK_PATRON
  );

  export const {
    id,
    email,
    barcode,
    firstName,
    lastName,
    address,
    address2,
    city,
    state,
    zipCode,
    phone1,
    branchId,
    optInReadingHistory,
    accountExpiration,
  } = generateSelectorsDependentOn(patron, FALLBACK_PATRON, NO_NAME_PREFIX);

  export const fullNameOrEmail: MxSelector<string> = createSelector(
    firstName,
    lastName,
    email,
    (firstName, lastName, email) =>
      (isPopulatedString(firstName) && isPopulatedString(lastName)) ?
        `${firstName} ${lastName}` : email
  );

  export const hasPatron: MxSelector<boolean> = createSelector(
    id,
    (patronId) => patronId > 0
  )

  export const accountEditValue: MxSelector<PatronAccountEditValue> = createSelector(
    firstName, lastName, address, address2, city, state, zipCode, phone1, branchId, optInReadingHistory,
    (firstName, lastName, address, address2, city, state, zipCode, phone1, branchId, optInReadingHistory) => ({
      firstName, lastName, address, address2, city, state, zipCode, phone1, branchId, optInReadingHistory
    } as PatronAccountEditValue)
  )

} // namespace Auth

export namespace AccountStatus {
  const subState: MxSelector<PatronState['patronAccountStatus']> = createSelector(
    entireState,
    state => state.patronAccountStatus
  );

  // LOAD STATUS
  export const {
    loading,
    awaitingFirstLoad,
    loaded,
    finishedLoading
  } = generateLoadStatusSelectorsFor(subState);

  export const status: MxSelector<PatronAccountStatus> = createSelector(
    subState,
    _subState => _subState.data ?? FALLBACK_PATRON_ACCOUNT_STATUS
  );

  export const {
    blocks,
    currentAccountBalance,
    maximumAccountBalance,
  } = generateSelectorsDependentOn(status, FALLBACK_PATRON_ACCOUNT_STATUS, NO_NAME_PREFIX);

  export const noBlocks: MxSelector<boolean> = createSelector(
    blocks,
    (_blocks) => _blocks.length === 0
  )

  export const underMaxBalance: MxSelector<boolean> = createSelector(
    currentAccountBalance,
    maximumAccountBalance,
    (currentBalance, maximumBalance) => currentBalance < maximumBalance
  )
} // namespace AccountStatus

export namespace Registration {
  const subState: MxSelector<PatronState['registration']> = createSelector(
    entireState,
    state => state.registration
  );

  export const completedSteps: MxSelector<string[]> = createSelector(
    subState,
    _subState => _subState.completedSteps
  );

  export const isComplete: MxSelectorCreator<[RegistrationStep], boolean> =
    step => createSelector(
      completedSteps,
      (_completedSteps) => _completedSteps.includes(step)
    );

  export const currentCreateAccountStep: MxSelector<number> = createSelector(
    completedSteps,
    (_completedSteps) => {
      for (const [index, item] of REGISTRATION_CREATE_ACCOUNT_STEPS.entries()) {
        if (!_completedSteps.includes(item)) {
          return index;
        }
      }
      // returning a step out of bounds so all steps show completed
      return REGISTRATION_CREATE_ACCOUNT_STEPS.length;
    }
  );

  export const currentLookupAccountStep: MxSelector<number> = createSelector(
    completedSteps,
    (_completedSteps) => {
      for (const [index, item] of REGISTRATION_LOOKUP_ACCOUNT_STEPS.entries()) {
        if (!_completedSteps.includes(item)) {
          return index;
        }
      }
      // returning a step out of bounds so all steps show completed
      return REGISTRATION_LOOKUP_ACCOUNT_STEPS.length;
    }
  );

  export const email: MxSelector<string> = createSelector(
    subState,
    _subState => _subState.email
  );

  export const cardNumber: MxSelector<string> = createSelector(
    subState,
    _subState => _subState.cardNumber
  );

  export const hasCardNumber: MxSelector<boolean> = createSelector(
    cardNumber,
    isPopulatedString
  );

  export const value: MxSelector<PatronRegistrationValue> = createSelector(
    subState,
    _subState => _subState.value
  );

  export const {
    verificationUUID,
    branchId,
    firstName,
    lastName,
    address,
    city,
    state,
    zip,
    dateOfBirth
  } = generateSelectorsDependentOn(value, FALLBACK_REGISTRATION_VALUE, NO_NAME_PREFIX);

  export const hasDateOfBirth: MxSelector<boolean> = createSelector(
    dateOfBirth,
    isPopulatedString
  );

  export const cityStateZip: MxSelector<string> = createSelector(
    city,
    state,
    zip,
    (_city, _state, _zip) =>
      `${_city}${isPopulatedString(_city) && isPopulatedString(_state) ? ', ' : ''}${_state} ${_zip}`.trim()
  );

  export const {
    firstAndLastName
  } = generatePersonNameSelectorsFrom(firstName, lastName, alwaysEmptyString, alwaysEmptyString);

} // namespace Registration

export namespace EmailValidation {
  const subState: MxSelector<PatronState['emailValidation']> = createSelector(
    entireState,
    state => state.emailValidation
  );

  export const isAvailable: MxSelector<boolean | 'UNKNOWN'> = createSelector(
    subState,
    _subState => _subState.isAvailable
  );
} // namespace EmailValidation


/**
 * TODO eventually remove this.
 * Many of the old services check that an auth token exists before making an api call.
 * They would subscribe to the patron before fetching their data.
 * Until we convert them to rxjs, we want to wait to load the components that include
 * these services until the auth actions have finished loading.
 * The services just need to know if we're currently logged in or not.
 */
export function authenticated(store?: Store): boolean {
  return snapshot(Auth.hasPatron, store);
}
