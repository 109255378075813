import {Pipe, PipeTransform} from '@angular/core';
import {isString} from '@store/common/typing.helpers';

const DEFAULT_MASK_CHARACTER = '*';
const DEFAULT_END_CHARACTERS_TO_KEEP = 4;

@Pipe({
  name: 'rvCharacterMask'
})
/** Example (with default arguments): `'1234567890'` &rarr; `'******7890'` */
export class CharacterMaskPipe implements PipeTransform {
  public transform(value: unknown, maskCharacter: string = DEFAULT_MASK_CHARACTER, endCharactersToKeep: number = DEFAULT_END_CHARACTERS_TO_KEEP): string {
    if (!isString(value)) return '';

    const length: number = value.length;
    if (length <= endCharactersToKeep) return value;

    const largestIndexToMask: number = length - endCharactersToKeep - 1;

    return value
      .split('')
      .map((originalCharacter, index) => index <= largestIndexToMask ? maskCharacter : originalCharacter)
      .join('');
  }
}
