import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {RV_STANDARD_ANIMATION_TIMINGS} from '@store/common/common.types';

export type ChevronOrientation = 'right' | 'down';

@Component({
  selector: 'rn-rotating-chevron',
  template: `
    <button mat-icon-button>
      <mat-icon [@rotateChevron]="orientation">chevron_right</mat-icon>
    </button>
  `,
  animations: [
    trigger('rotateChevron', [
      state('right', style({transform: 'rotate(0deg)'})),
      state('down', style({transform: 'rotate(90deg)'})),
      transition('right <=> down', animate(RV_STANDARD_ANIMATION_TIMINGS)),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RotatingChevronComponent {
  @Input() public orientation: ChevronOrientation = 'right';
}
