import {createReducer, on} from '@ngrx/store';
import {INITIAL_LOAD_STATUS_SUBSTATE, LoadStatusSubstate} from '@store/store.types';
import {Checkout, CheckoutClaim} from '@store/checkouts/checkouts.types';
import {FAILED, LOADED, LOADING} from '@store/common/common.types';
import {CheckoutsActions} from '@store/checkouts/checkouts.actions';

export interface CheckoutsState {
  checkedOut: LoadStatusSubstate & {
    data: Checkout[];
  };
  claims: LoadStatusSubstate & {
    data: CheckoutClaim[];
  };
}

export const INITIAL_CHECKOUTS_STATE: CheckoutsState = {
  checkedOut: {
    ...INITIAL_LOAD_STATUS_SUBSTATE,
    data: [],
  },
  claims: {
    ...INITIAL_LOAD_STATUS_SUBSTATE,
    data: [],
  }
};

export const checkoutsState = createReducer(
  INITIAL_CHECKOUTS_STATE,
  on(CheckoutsActions.loadCheckedOut, (state) => ({
    ...state,
    checkedOut: {
      ...state.checkedOut,
      loadStatus: LOADING
    }
  })),
  on(CheckoutsActions.loadCheckedOutSuccess, (state, {listData: {data}}) => ({
    ...state,
    checkedOut: {
      ...state.checkedOut,
      loadStatus: LOADED,
      data
    }
  })),
  on(CheckoutsActions.loadCheckedOutFailure, state => ({
    ...state,
    checkedOut: {
      ...INITIAL_CHECKOUTS_STATE.checkedOut,
      loadStatus: FAILED,
    }
  })),
  on(CheckoutsActions.loadCheckoutClaims, (state) => ({
    ...state,
    claims: {
      ...state.claims,
      loadStatus: LOADING
    }
  })),
  on(CheckoutsActions.loadCheckoutClaimsSuccess, (state, {listData: {data}}) => ({
    ...state,
    claims: {
      ...state.claims,
      loadStatus: LOADED,
      data,
    }
  })),
  on(CheckoutsActions.loadCheckoutClaimsFailure, state => ({
    ...state,
    claims: {
      ...INITIAL_CHECKOUTS_STATE.claims,
      loadStatus: FAILED,
    }
  })),
);
