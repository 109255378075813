import {Pipe, PipeTransform} from '@angular/core';

/**
 This is a very limited formatter that assumes US/Canadian phone numbers -- if there aren't exactly ten digits (after ignoring non-numeric characters), it
 returns the input unchanged.
 */
@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value?: string | null): string {
    const rawValue: string = value?.replace(/\D/g, '') ?? ''; // Remove all non-digit characters.

    return rawValue.length === 10
      ? rawValue.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      : (value ?? '');
  }
}
