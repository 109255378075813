import {AfterViewInit, Directive, ElementRef, HostListener, inject, Input} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';

@Directive({
  selector: '[rnTruncate]',
  providers: [MatTooltip]
})
export class TruncateDirective implements AfterViewInit {

  /**
   *  If we ever need truncation with more than 6 lines, we need to update lineClampClassname() below, and our Tailwind theme to support new classes.
   *  See https://tailwindcss.com/docs/line-clamp#customizing-your-theme
   */
  @Input() public rnTruncateMaxLines: number = 1;

  private element: HTMLElement = inject(ElementRef).nativeElement;
  private tooltip: MatTooltip = inject(MatTooltip);

  public ngAfterViewInit(): void {
    const classesSpecificToMaxLines: string[] =
      this.rnTruncateMaxLines === 1
        ? ['block', 'whitespace-nowrap', 'overflow-hidden']  // Clamping doesn't suit our needs for limiting the width of a single line, so handle separately...
        : [this.lineClampClassname];                         // ...but at 2 or more lines, it works great.

    this.element.classList.add(...classesSpecificToMaxLines, 'text-ellipsis', 'hyphens-auto');
  }

  @HostListener('mouseenter')
  public mouseover(): void {
    if (this.fullTextIsVisible) return; // No need for a tooltip.

    this.tooltip.message = this.element.innerText;
    this.tooltip.show();
  }

  @HostListener('mouseleave')
  public mouseleave(): void {
    this.tooltip.hide();
  }

  private get fullTextIsVisible(): boolean {
    if (this.rnTruncateMaxLines === 1) return this.element.offsetWidth >= this.element.scrollWidth;

    return this.element.offsetHeight >= this.element.scrollHeight;
  }

  private get lineClampClassname(): string {
    /*
      This looks like really stupid code (because it is), but generating classnames with interpolation doesn't work well for Tailwind CSS because it won't
      guarantee that classnames will always be loaded if we don't reference them in full.  There are other workarounds, but this is simpler and local-er than
      those.  (Don't poke the bear.)

      See https://tailwindcss.com/docs/content-configuration#dynamic-class-names.
    */
    switch (this.rnTruncateMaxLines) {
      case 2:
        return 'line-clamp-2';
      case 3:
        return 'line-clamp-3';
      case 4:
        return 'line-clamp-4';
      case 5:
        return 'line-clamp-5';
      case 6:
        return 'line-clamp-6';

      default:
        return '';
    }
  }
}
