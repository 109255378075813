import {LogoutComponent} from './core/login/logout.component';
import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppLayoutComponent} from './_layout/app-layout.component';
import {CatalogHomeComponent} from './core/catalog/catalog-home.component';
import {Error401Component} from './core/error-pages/401.component';
import {Error403Component} from './core/error-pages/403.component';
import {Error404Component} from './core/error-pages/404.component';
import {Error500Component} from './core/error-pages/500.component';
import {RegistrationAgeValidationComponent} from './core/registration/registration-age-validation.component';
import {IndexComponent} from './core/index/index.component';
import {AuthGuard} from './core/guards/auth.guard';
import {CatalogBrowseComponent} from './core/catalog/catalog-browse/catalog-browse.component';
import {CatalogItemDetailsComponent} from './core/catalog/catalog-item-details/catalog-item-details.component';
import {FavoritesComponent} from './core/favorites/favorites.component';
import {HoldsComponent} from './core/holds/holds.component';
import {ProfileComponent} from './core/profile/profile.component';
import {PatronCheckoutsComponent} from './core/patron-checkouts/patron-checkouts.component';
import {ItemRequestListComponent} from './core/item-requests/item-request-list/item-request-list.component';
import {ItemDetailsComponent} from './core/item-requests/item-details/item-details.component';
import {ItemRequestDetailsComponent} from './core/item-requests/item-request-details/item-request-details.component';
import {NotificationPreferencesComponent} from './core/profile/notification-preferences.component';
import {MyCardComponent} from './core/profile/my-card.component';
import {NamedRouteChildNames, NamedRoutePaths, NamedRouteTabData, NamedRouteTabIndices} from '@store/router/router.types';
import {
  extractRouteChildNamesByNameFrom,
  extractRoutePathsByNameFrom,
  extractTabDataByNameFrom,
  extractTabIndicesByNameFrom
} from '@store/router/router.extractors';
import {NotLoggedInGuard} from './core/guards/not-logged-in.guard';
import {LookupAccountComponent} from './core/registration/lookup-account.component';
import {CreateAccountComponent} from './core/registration/create-account.component';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {path: '', component: IndexComponent, canActivate: [NotLoggedInGuard], data: {name: 'INDEX'}},

      {path: 'login', component: IndexComponent, canActivate: [NotLoggedInGuard], data: {name: 'LOGIN', title: 'Login'}},
      {path: 'logout', component: LogoutComponent, data: {name: 'LOGOUT', title: 'Logout'}},

      // error pages
      {path: '401', component: Error401Component, data: {name: 'ERROR_401'}},
      {path: '403', component: Error403Component, data: {name: 'ERROR_403'}},
      {path: '404', component: Error404Component, data: {name: 'ERROR_404'}},
      {path: '500', component: Error500Component, data: {name: 'ERROR_500'}},

      {
        path: '', children: [
          {path: 'dashboard', component: CatalogHomeComponent, data: {name: 'DASHBOARD', footerVisible: false, title: 'Dashboard'}},
          // catalog
          {path: 'catalog', component: CatalogHomeComponent, data: {name: 'CATALOG', footerVisible: false, title: 'Catalog'}},
          {path: 'catalog-browse', component: CatalogBrowseComponent, data: {name: 'CATALOG_BROWSE', title: 'Catalog'}},
          {path: 'catalog-item/:id', component: CatalogItemDetailsComponent, data: {name: 'CATALOG_ITEM', title: 'Catalog'}},
        ]
      },

      // registration routes
      {
        data: {navbarVisible: false},
        path: '', children: [
          {path: 'register', component: CreateAccountComponent, canActivate: [NotLoggedInGuard], data: {name: 'REGISTER', navbarVisible: false}},
          {path: 'register-existing-card', component: LookupAccountComponent, data: {name: 'REGISTER_EXISTING_CARD', navbarVisible: false}},
          //
          // // {path: 'register/verification', component: RegistrationAccountCreationComponent},
          // {path: 'register/patron-info', component: RegistrationPersonalInfoComponent, data: {name: 'REGISTER_INFO', navbarVisible: false}},
          // {path: 'register/notifications', component: RegistrationNotificationPrefComponent, data: {name: 'REGISTER__NOTIFICATIONS', navbarVisible: false}},
          {path: 'register/in-person', component: RegistrationAgeValidationComponent, data: {name: 'REGISTER_IN_PERSON', navbarVisible: false}},
        ]
      },

      // authenticated routes
      {
        canActivate: [AuthGuard],
        path: '', children: [

          // favorites
          {path: 'favorites', component: FavoritesComponent, data: {name: 'FAVORITES', footerVisible: false, title: 'Favorites'}},

          // holds
          {path: 'holds', component: HoldsComponent, data: {name: 'HOLDS', footerVisible: false, title: 'Holds'}},
          {path: 'profile', component: ProfileComponent, data: {name: 'PROFILE', footerVisible: false, title: 'Profile'}},
          {path: 'checkouts', component: PatronCheckoutsComponent, data: {name: 'CHECKOUTS', footerVisible: false, title: 'Checked Out'}},

          // patron item requests
          {path: 'patron-item-requests-v2', component: ItemRequestListComponent, data: {name: 'ITEM_REQUESTS', title: 'Item Requests', footerVisible: false}},
          {path: 'outside-item-details/:isbn', component: ItemDetailsComponent, data: {name: 'OUTSIDE_ITEM_DETAILS', title: 'Outside Item Details'}},
          {
            path: 'item-request-details/:id',
            component: ItemRequestDetailsComponent,
            data: {name: 'ITEM_REQUEST_DETAILS', title: 'Item Request Details', footerVisible: false}
          },

          {path: 'profile', component: ProfileComponent, data: {name: 'PROFILE', footerVisible: false, title: 'Profile'}},
          {
            path: 'notification-preferences',
            component: NotificationPreferencesComponent,
            data: {name: 'NOTIFICATION_PREFERENCES', title: 'Notification Preferences'}
          },
          {path: 'my-card', component: MyCardComponent, data: {name: 'MY_CARD', title: 'My Card'}},
        ]
      }
    ],
  },

  // fallthrough if nothing else matches redirect to '/'
  {path: '**', redirectTo: '/', pathMatch: 'full'},
];

/**
 * Given a route name, get its route path.
 *
 * @example `PATH_FOR['PATRON_OVERVIEW']` &rarr; `/patrons/:id/overview`
 */
export const PATH_FOR: NamedRoutePaths = extractRoutePathsByNameFrom(routes);

/**
 * Given a route name, get the route names of its children, or an empty array if the route has no children.
 *
 * @example `CHILD_ROUTE_NAMES_FOR['PATRON_DETAILS']` &rarr; `['PATRON_OVERVIEW', 'PATRON_NOTES', etc.]`
 * @example `CHILD_ROUTE_NAMES_FOR['PATRON_NOTES']` &rarr; `[]`
 */
export const CHILD_ROUTE_NAMES_FOR: NamedRouteChildNames = extractRouteChildNamesByNameFrom(routes);

/**
 * Given a route name, get the router tab data for its children, or an empty array when the route has no children that represent tabs.
 *
 * @example `ROUTER_TABS_FOR['PATRON_DETAILS']` &rarr; `[{index: 0, routeName: 'PATRON_OVERVIEW', label: 'Overview'}, etc.]`
 * @example `ROUTER_TABS_FOR['PATRON_HOLDS']` &rarr; `[]`
 */
export const ROUTER_TABS_FOR: NamedRouteTabData = extractTabDataByNameFrom(routes);

/**
 * Given a route name, get its router tab index, or `undefined` when the route doesn't represent a tab.
 *
 * @example `ROUTER_TAB_INDEX_FOR['PATRON_ITEMS_OUT']` &rarr; `2`
 * @example `ROUTER_TAB_INDEX_FOR['PATRON_DETAILS']` &rarr; `undefined`
 */
export const ROUTER_TAB_INDEX_FOR: NamedRouteTabIndices = extractTabIndicesByNameFrom(ROUTER_TABS_FOR);


@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
