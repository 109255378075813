import {ChangeDetectionStrategy, Component, HostBinding, inject} from '@angular/core';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {Store} from '@ngrx/store';
import {DecimalPipe} from '@angular/common';
import {RouterActions} from '@store/router/router.actions';
import {PaginationSelectors} from '@store/store.selectors';

class CustomPaginator extends MatPaginatorIntl {
  constructor(readonly decimalPipe: DecimalPipe) {
    super();
    this.itemsPerPageLabel = 'Rows per page:';

    const originalGetRangeLabel: typeof this.getRangeLabel = this.getRangeLabel;

    this.getRangeLabel =
      (page: number, pageSize: number, length: number) =>
        originalGetRangeLabel(page, pageSize, length)
          .replace(/ [-\u2012\u2013\u2014\u2015] /, '-') // Replace any em-dash, en-dash, etc. in the original label and remove surrounding spaces.
          .replace(/of.*$/, `of ${decimalPipe.transform(length)}`); // Use Angular "number" pipe to format the length with commas.
  }
}

@Component({
  selector: 'rn-pagination',
  template: `
    <mat-paginator
      [pageSizeOptions]="[3,5,10,12,20]"
      [pageIndex]="selectors.zeroBasedPage | select"
      [pageSize]="selectors.pageSize | select"
      [length]="selectors.totalCount | select"
      [selectConfig]="{ panelClass: 'hk-pagination' }"
      (page)="onPageEvent($event)"/>
  `,
  providers: [
    DecimalPipe,
    {provide: MatPaginatorIntl, deps: [DecimalPipe], useFactory: (decimalPipe: DecimalPipe) => new CustomPaginator(decimalPipe)}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {

  @HostBinding('class.loaded') public loaded: boolean = true;

  public readonly selectors = PaginationSelectors;

  private readonly store: Store = inject(Store);

  public onPageEvent(event: PageEvent): void {
    this.loaded = false;
    document.body.focus();

    // MatPaginator is 0-based, but we are 1-based.
    this.store.dispatch(RouterActions.updatePaginationParams(event.pageIndex + 1, event.pageSize));
  }
}
