import {NgModule} from '@angular/core';
import {ExpansionToggleComponent} from './row-expansion/expansion-toggle.component';
import {RotatingChevronComponent} from './rotating-chevron/rotating-chevron.component';
import {SharedModule} from '../core/shared/shared.module';
import {AllRowsSelectionCheckboxComponent} from './row-selection/all-rows-selection-checkbox/all-rows-selection-checkbox.component';
import {RowSelectionCheckboxComponent} from './row-selection/row-selection-checkbox/row-selection-checkbox.component';
import {TableComponent} from './table.component';
import {DynamicComponentHostDirective} from './dynamic-component-host/dynamic-component-host.directive';
import {TableHeightAdjusterComponent} from './table-height-adjuster/table-height-adjuster.component';
import {HiddenUntilLoadedDirective} from './hidden-until-loaded/hidden-until-loaded.directive';

@NgModule({
  declarations: [
    AllRowsSelectionCheckboxComponent,
    DynamicComponentHostDirective,
    ExpansionToggleComponent,
    HiddenUntilLoadedDirective,
    RotatingChevronComponent,
    RowSelectionCheckboxComponent,
    TableComponent,
    TableHeightAdjusterComponent,
  ],
  imports: [
    SharedModule,
  ],
  providers: [],
  exports: [TableComponent],
})
export class TableModule {
}
