import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, Input} from '@angular/core';
import {RowSelectionService} from '../row-selection.service';

interface Tooltips {
  whenUnselected: string;
  whenSelected: string;
  whenDisabled: string;
}

@Component({
  selector: 'rn-row-selection-checkbox',
  template: `
    <div [matTooltip]="tooltipText">
      <mat-checkbox color="primary" [checked]="isSelected" [disabled]="!enabled" (change)="onChangeCheckbox()"/>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RowSelectionCheckboxComponent<RowObject> implements AfterViewInit {
  @Input() rowObject: RowObject;
  @Input() enabled: boolean;
  @Input() tooltips: Tooltips;

  public ngAfterViewInit(): void {
    const parentTableRow: HTMLTableRowElement | null = (this.elementReference.nativeElement as HTMLElement).closest('tr');
    if (!parentTableRow) throw Error('RowSelectionCheckboxComponent needs to be inside a <tr> element');

    this.parentTableRow = parentTableRow;
  }

  public get isSelected(): boolean {
    return this.rowSelectionService.isSelected(this.rowObject);
  }

  public get tooltipText(): string {
    if (!this.enabled) return this.tooltips?.whenDisabled ?? '';

    return (this.isSelected ? this.tooltips?.whenSelected : this.tooltips?.whenUnselected) ?? '';
  }

  public onChangeCheckbox(): void {
    this.rowSelectionService.toggleSelectionFor(this.rowObject, this.parentTableRow);
  }

  private readonly elementReference: ElementRef = inject(ElementRef);
  private readonly rowSelectionService: RowSelectionService<RowObject> = inject(RowSelectionService);
  private parentTableRow: HTMLTableRowElement;
}
