import {createActionGroup, emptyProps} from '@ngrx/store';
import {Checkout, CheckoutClaim, CHECKOUTS_FEATURE_KEY} from '@store/checkouts/checkouts.types';
import {ListData} from '@store/common/common.types';

export const CheckoutsActions = createActionGroup({
  source: CHECKOUTS_FEATURE_KEY,
  events: {
    'Load Checked Out': emptyProps(),
    'Load Checked Out Success': (listData: ListData<Checkout>) => ({listData}),
    'Load Checked Out Failure': (error: unknown) => ({error}),

    'Load Checkout Claims': emptyProps(),
    'Load Checkout Claims Success': (listData: ListData<CheckoutClaim>) => ({listData}),
    'Load Checkout Claims Failure': (error: unknown) => ({error}),
  },
});
