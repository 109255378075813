<ng-container *ngIf="{
  hasData: paginationSelectors.hasData | select,
  awaitingFirstLoad: defaultSelectorContainer.awaitingFirstLoad | select,
  awaitingAnyLoad: defaultSelectorContainer.awaitingAnyLoad | select:false,
  rowCount: rowCountSelector | select:0
} as vm">
  <rn-table-height-adjuster>
    <table mat-table [dataSource]="dataSource" [attr.aria-label]="tableName"
           matSort (matSortChange)="onMatSortChange($event)"
           [multiTemplateDataRows]="useExpansionComponents"
           [ngClass]="{ 'hk-table-no-pagination': !showPagination }">
      <tr mat-header-row *matHeaderRowDef="vm.awaitingAnyLoad || vm.hasData ? columnOrder : columnOrderForNoData"></tr>
      <tr mat-row *matRowDef="let row; columns: columnOrder" [ngClass]="generateRowCssClassesFor(row)"></tr>

      <ng-container *ngIf="useHiddenDataIndicatorRow">
        <tr mat-footer-row *matFooterRowDef="hiddenDataIndicatorColumnOrder | select:[]" class="bg-background"
            [class.h-0]="!(showHiddenDataIndicatorRow | select:false)">
      </ng-container>

      <ng-container *ngIf="useExpansionComponents">
        <tr mat-row class="hk-expansion-row" *matRowDef="let row; columns: ['expanded']"
            [ngClass]="{ 'hk-expansion-row-expanded': rowExpansionService.isExpanded(row) }"></tr>
      </ng-container>

      <ng-container matColumnDef="expanded">
        <td *matCellDef="let row" mat-cell [attr.colspan]="numberOfDataColumns">
          <div class="hk-expansion-row-content" [@expansionRowContentTransition]="rowExpansionService.isExpanded(row) ? 'expanded' : 'collapsed'">
            <ng-template #rowComponentContainer rvDynamicComponentHost [data]="{ row: row }"></ng-template>
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="useStandardNoDataMessageRow">
        <!--
          We have to use mat-* CSS classes with *matNoDataRow instead of the usual mat-* directives we use everywhere else, because... reasons?
          See https://stackoverflow.com/questions/62698551/angular-material-matnodatarow-doesnt-work#comment112097235_62706478
        -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell border-b-0" [colSpan]="columnOrderForNoData.length">
            <p rnHiddenUntilLoaded [reveal]="!vm.awaitingFirstLoad">
              {{ noDataRowMessage | select }}
            </p>
          </td>
        </tr>
      </ng-container>

      <ng-container matColumnDef="_expansionRowToggle">
        <th mat-header-cell *matHeaderCellDef scope="col" class="hk-header-cell-for-icon-button"></th>

        <td mat-cell *matCellDef="let row">
          <div class="hk-cell-content">
            <rn-expansion-toggle *ngIf="showExpansionControlFor(row)" [rowObject]="row"/>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="_rowSelectionCheckboxes">
        <th mat-header-cell *matHeaderCellDef scope="col" class="hk-header-cell-for-icon-button">
          <div class="hk-cell-content">
            <rn-all-rows-selection-checkbox [data]="rowSelectionDataSourceSelector | select:[]" [rowSelectionChangeAction]="rowSelectionChangeAction"/>
          </div>
        </th>

        <td mat-cell *matCellDef="let row">
          <div class="hk-cell-content">
            <rn-row-selection-checkbox [rowObject]="row" [enabled]="rowSelectionEnabledFor(row)" [tooltips]="rowSelectionTooltipsFor(row)"/>
          </div>
        </td>
      </ng-container>

      <ng-container *ngFor="let column of columns; trackBy: trackByColumnName; index as columnIndex" [matColumnDef]="column.columnName">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.sortKey" [disabled]="!column.sortable || !vm.rowCount"
            class="{{ column.headerCssClasses }}">
          <div class="hk-cell-content" [matTooltip]="column.headerToolTip">
            {{ column.header }}
          </div>
        </th>

        <td mat-cell *matCellDef="let row" [ngClass]="generateCellCssClassesFor(row, column.cssClasses)">
          <div class="hk-cell-content">
            <ng-container *ngIf="column.isComponent; then cellDataAsComponent; else cellDataAsValue"/>

            <ng-template #cellDataAsComponent>
              <ng-template #cellComponentContainer rvDynamicComponentHost [data]="{ row: row, column: column }"/>
            </ng-template>

            <ng-template #cellDataAsValue>
              <ng-container *ngIf="column.isChip; then cellDataAsChip; else cellDataAsText"/>
            </ng-template>

            <ng-template #cellDataAsChip>
              <mat-chip-set>
                <ng-container *ngIf="column.isStatusChip; then cellDataAsStatusChip; else cellDataAsGenericChip"/>
              </mat-chip-set>
            </ng-template>

            <ng-template #cellDataAsStatusChip>
              <rn-status-chip *ngIf="column.valueSelectorCreator" [status]="column.valueSelectorCreator(row) | select" [colors]="column.statusChipColors"
                              [displayableStatusNames]="column.displayableStatusNames"/>
            </ng-template>

            <ng-template #cellDataAsGenericChip>
              <mat-chip *ngIf="column.valueSelectorCreator" [color]="column.chipColorFor(row)">
                {{ column.valueSelectorCreator(row) | select | format:column.format }}
              </mat-chip>
            </ng-template>

            <ng-template #cellDataAsText>
              <ng-container *ngIf="column.isLink; then cellDataAsLink; else cellDataOnly"/>
            </ng-template>

            <ng-template #cellDataAsLink>
              <ng-container *ngIf="column.isSimpleLink; then cellDataAsSimpleLink; else cellDataAsRouterLink"/>
            </ng-template>

            <ng-template #cellDataAsSimpleLink>
              <a (click)="column.clickHandler(row)">
                <ng-container *ngTemplateOutlet="cellDataOnly"/>
              </a>
            </ng-template>

            <ng-template #cellDataAsRouterLink>
              <a [rvNamedRouterLink]="column.routeName" [rvNamedRouterLinkParams]="buildLinkParamsFor(column, row)">
                <ng-container *ngTemplateOutlet="cellDataOnly"/>
              </a>
            </ng-template>

            <ng-template #cellDataOnly>
              <ng-container *ngIf="column.truncate; then cellDataWithTruncation; else cellDataWithoutTruncation"/>
            </ng-template>

            <ng-template #cellDataWithTruncation>
            <span *ngIf="column.valueSelectorCreator" rnTruncate>
              {{ column.valueSelectorCreator(row) | select | format:column.format }}
            </span>
            </ng-template>

            <ng-template #cellDataWithoutTruncation>
            <span *ngIf="column.valueSelectorCreator">
              {{ column.valueSelectorCreator(row) | select | format:column.format }}
            </span>
            </ng-template>
          </div>

          <div *ngIf="useExpansionCells" class="hk-expansion-content"
               [@expansionRowContentTransition]="rowExpansionService.isExpanded(row) ? 'expanded' : 'collapsed'">
            <div *ngFor="let expansionCellDefinition of expansionCellDefinitionsFor(columnIndex); trackBy: trackByIndex" class="flex flex-col">
              <span rnTruncate class="min-h-[1lh] text-title-small">{{ expansionCellDefinition.headerFor(row) }}</span>

              <ng-container *ngIf="expansionCellDefinition.hasTextValue; then textExpansionValue; else componentExpansionValue"/>

              <ng-template #textExpansionValue>
                <span *ngIf="expansionCellDefinition.hasTextValue" rnTruncate class="min-h-[1lh]">
                  {{ expansionCellDefinition.valueFor(row) | format:expansionCellDefinition.format }}
                </span>
              </ng-template>

              <ng-template #componentExpansionValue>
                <ng-template #expansionCellComponentContainer rvDynamicComponentHost
                             [data]="{
                               row: row,
                               componentClass: expansionCellDefinition.hasComponentValue ? expansionCellDefinition.componentClass: undefined,
                               componentProperties: expansionCellDefinition.hasComponentValue ? expansionCellDefinition.componentProperties : {}
                             }"/>
              </ng-template>
            </div>
          </div>
        </td>

        <td mat-footer-cell *matFooterCellDef [colSpan]="columnOrder.length">
          <div class="flex flex-row justify-center items-center gap-x-1">
            <span>{{ hiddenDataIndicatorRowMessage | select }}</span>

            <button mat-button (click)="hiddenDataIndicatorRowClickHandler()">
              {{ hiddenDataIndicatorRowButtonLabel | select }}
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="_actions">
        <!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -- This is a dynamic style that is calculated programmatically. -->
        <th mat-header-cell *matHeaderCellDef [style.width.rem]="actionsColumnWidthInRems | async"></th>

        <td mat-cell *matCellDef="let row">
          <div class="flex flex-row justify-end">
            <ng-container *ngIf="showActionButtons">
              <div *ngFor="let actionButton of actionButtons; trackBy: trackByIndex" class="hk-cell-content">
                <button mat-icon-button *ngIf="actionButton.visibleFor(row)"
                        [matTooltip]="actionButton.tooltipFor(row)"
                        [color]="actionButton.colorFor(row)"
                        [disabled]="!actionButton.enabledFor(row)"
                        (click)="actionButton.clickHandler(row)">
                  <span *ngIf="actionButton.badgeFor(row) else superscript">
                    <mat-icon [ngClass]="generateCellCssClassesFor(row, actionButton.cssClasses)" [matBadge]="actionButton.badgeFor(row)" matBadgeSize="small"
                              class="mat-badge-error top-[0.375rem]">  <!-- Adding a badge moves the icon up for some reason, so nudge it back down. -->
                      {{ actionButton.iconName }}
                    </mat-icon>
                  </span>

                  <ng-template #superscript>
                  <span *ngIf="actionButton.superscriptFor(row) else buttonIcon" [matBadge]="actionButton.superscriptFor(row)" class="mat-badge-transparent">
                    <ng-container *ngTemplateOutlet="buttonIcon"/>
                  </span>
                  </ng-template>

                  <ng-template #buttonIcon>
                    <mat-icon [ngClass]="generateCellCssClassesFor(row, actionButton.cssClasses)">{{ actionButton.iconName }}</mat-icon>
                  </ng-template>
                </button>
              </div>
            </ng-container>

            <ng-container *ngIf="showKebabMenuActions">
              <div *ngIf="visibleKebabMenuActionsFor(row) as visibleKebabMenuActions; else noKebabButton" class="hk-cell-content">
                <button mat-icon-button [matMenuTriggerFor]="rowActionsMenu">
                  <mat-icon class="text-surface-contrast">more_vert</mat-icon>
                </button>

                <mat-menu #rowActionsMenu="matMenu">
                  <ng-template matMenuContent>
                    <button mat-menu-item *ngFor="let kebabMenuAction of visibleKebabMenuActions; trackBy: trackByKebabMenuItemLabel"
                            (click)="kebabMenuAction.clickHandler(row)">
                      <span>{{ kebabMenuAction.label }}</span>
                    </button>
                  </ng-template>
                </mat-menu>
              </div>
            </ng-container>

            <ng-template #noKebabButton>
              <button mat-icon-button [disabled]="true">
                <!-- No icon.  This button takes up space like a kebab button, but is effectively not there because it's invisible and disabled. -->
              </button>
            </ng-template>
          </div>
        </td>
      </ng-container>
    </table>
  </rn-table-height-adjuster>
  <!-- Note that the "loaded" class is removed inside PaginationComponent when the user clicks the previous/next page buttons, and eventually restored here. -->
  <rn-pagination *ngIf="showPagination && vm.hasData" [class.loaded]="!vm.awaitingAnyLoad"/>

  <div *ngIf="useCustomNoDataComponent" [hidden]="vm.awaitingFirstLoad || vm.hasData">
    <ng-template #customNoDataComponentContainer rvDynamicComponentHost></ng-template>
  </div>
</ng-container>
