<div class="catalog-footer-background hide-xs">
  <div class="catalog-footer-body" *ngIf="patronSelectors.hasPatron | select else simpleFooter">
    <div class="catalog-footer-section flex-row">
      <div class="flex-col flex-gap-16 flex-1">
        <div class="flex-row flex-center-start">
          <img style="height: 25px; width: 25px" alt="Library Logo" src="/assets/mx-logo.png"/>
          <h3 style="margin: 0 8px">{{ organizationSelectors.name | select }}</h3>
        </div>
        <div class="flex-row flex-center-start">
          <img alt="notifications" src="/assets/slicons/arrow_backward.svg"/>
          <a routerLink="/dashboard">Library Home</a>
        </div>
        <div *ngIf="branch$ | async as branch">
          <span class="footer-heading">Branch Location</span><br/>
          <ng-container *ngIf="branch.address && branch.phone; else placeholderBranchInfo">
            <span>{{ branchSelectors.address }}</span><br/>
            <span *ngIf="branchSelectors.address2 | select">{{ branchSelectors.address2 | select }}<br/></span>
            <span>{{ branchSelectors.city | select }}, {{ branchSelectors.state | select }} {{ branchSelectors.zipCode | select }}</span><br/>
            <span>{{ branchSelectors.phone | select }}</span>
          </ng-container>
          <ng-template #placeholderBranchInfo>
            <span>1234 Street Name</span><br/>
            <span>City, ST, 12345</span><br/>
            <span>123-456-7790</span>
          </ng-template>
        </div>
      </div>
      <div class="flex-col flex-gap-16 flex-1">
        <a routerLink="/catalog">Catalog</a>
        <a routerLink="/checkouts">Items Out</a>
        <a routerLink="/holds">On Hold</a>
        <a>Requests</a>
        <a routerLink="/favorites">Favorites</a>
        <a routerLink="/my-card">Library Card</a>
      </div>
      <div class="flex-col flex-gap-16 flex-1">
        <a (click)="routingService.goToProfile()">Account</a>
        <a (click)="routingService.goToPatronBilling()">Fees and Fines</a>
        <a (click)="routingService.goToPatronPaymentInfo()">Payment Information</a>
        <a (click)="routingService.goToNotificationPreferences()">Notification Preferences</a>
      </div>
    </div>
    <hr/>
    <div class="flex-row flex-center-space-between" style="padding: 20px 0">
      <div>
        <span>Powered by Simplicity™ All rights reserved ©2025</span>
      </div>
      <div>
        <a (click)="privacyPolicy()">Privacy Policy</a>
        <span> | </span>
        <a (click)="termsAndConditions()">Terms of Use</a>
        <span> | </span>
        <a (click)="cookiesDialog()">Cookies</a>
      </div>
      <div>
        <a routerLink="/logout">Sign Out</a>
      </div>
    </div>
  </div>
  <ng-template #simpleFooter>
    <div class="catalog-footer-body">
      <div class="flex-row flex-center-space-between" style="padding: 20px 0">
        <div>
          <span>Powered by Simplicity™ All rights reserved ©2025</span>
        </div>
        <div>
          <a (click)="privacyPolicy()">Privacy Policy</a>
          <span> | </span>
          <a (click)="termsAndConditions()">Terms of Use</a>
          <span> | </span>
          <a (click)="cookiesDialog()">Cookies</a>
        </div>
        <div></div>
      </div>
    </div>
  </ng-template>
</div>
