import {Directive, inject, Input, OnChanges} from '@angular/core';
import {Params, RouterLink} from '@angular/router';
import {Store} from '@ngrx/store';
import {QueryParamsSelectors} from '@store/store.selectors';
import {take} from 'rxjs';
import {buildValidatedRouterPathFrom} from '@store/router/router.helpers';

@Directive({
  selector: 'a[rvNamedRouterLink]',
  hostDirectives: [RouterLink]
})
export class NamedRouterLinkDirective implements OnChanges {
  /** The `name` property from some route's `data` object. */
  @Input() public rvNamedRouterLink: string;

  /** The params needed to fill a raw route path's parameter variables.
   *
   * For example, if the route is `/something/:id/child/:childId`, the params should be an object like `{ id: 42, childId: 99 }`.
   */
  @Input() public rvNamedRouterLinkParams: Params = {};
  @Input() public rvQueryParams?: Params;

  public ngOnChanges(): void {
    this.updateLink();
  }

  private readonly store: Store = inject(Store);
  private readonly angularRouterLinkDirective = inject(RouterLink);

  private updateLink(): void {
    if (this.rvQueryParams) {
      this.angularRouterLinkDirective.queryParams = {...this.rvQueryParams};
      this.updateRouterLink();
    } else {
      this.store.select(QueryParamsSelectors.cachedParamsFor(this.rvNamedRouterLink))
        .pipe(take(1))
        .subscribe(queryParams => {
          this.angularRouterLinkDirective.queryParams = {...queryParams};
          this.updateRouterLink();
        });
    }
  }

  private updateRouterLink(): void {
    this.angularRouterLinkDirective.routerLink = buildValidatedRouterPathFrom(this.rvNamedRouterLink, this.rvNamedRouterLinkParams);

    // eslint-disable-next-line @angular-eslint/no-lifecycle-call -- We need to call this on the original directive to keep it up to date.
    this.angularRouterLinkDirective.ngOnChanges({});
  }
}
