import {inject, Pipe, PipeTransform} from '@angular/core';
import {PhonePipe} from './phone.pipe';
import {CurrencyPipe, DatePipe, DecimalPipe, PercentPipe, TitleCasePipe, UpperCasePipe} from '@angular/common';
import {asArray, hasNoValue, isDate, isDateOnlyRangeIsoString, isDateString} from '@store/common/typing.helpers';
import {OneOrArrayOf} from '@store/utility-type.helpers';
import {toDateTimeIsoString} from '@store/common/date.helpers';
import {ReplaceEmptyPipe} from './replace-empty.pipe';
import {CharacterMaskPipe} from './character-mask.pipe';
import {YesOrNoPipe} from './yes-or-no.pipe';

export type Format = 'NONE' | 'REPLACE_EMPTY' | 'YES_OR_NO'
  | 'NUMBER' | 'PERCENT_AS_IS' | 'PERCENT_X_100' | 'PHONE' | 'TITLE_CASE' | 'UPPER_CASE' | 'CURRENCY' | 'DEFAULT_CHARACTER_MASK'
  | 'DATE_ONLY' | 'LONG_DATE_ONLY' | 'SHORT_DATE_ONLY' | 'DATE_TIME' | 'LONG_DATE_TIME'
  | 'DATE_AT_TIME' | 'DATE_ONLY_RANGE' | 'TIME_ONLY' | 'HOUR_ONLY'
  | 'DATE_FULL_WEEKDAY_ONLY' | 'DATE_ABBREVIATED_WEEKDAY_ONLY' | 'DATE_ABBREVIATED_MONTH_ONLY' | 'DATE_DAY_NUMBER_ONLY';

const DATE_FORMAT = 'MM/dd/yyyy';
const LONG_DATE_FORMAT = 'MMMM d, y';
const SHORT_DATE_FORMAT = 'MM/yy';
const TIME_FORMAT = 'h:mmaaa'; // 'aaa' is for AM/PM.  Case is apparently locale-dependent and unconfigurable, so we must force it to lowercase ourselves.
const PERCENT_DIGIT_INFO = '1.0-2';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {
  public transform(value: unknown, format: OneOrArrayOf<Format>): string {
    if (!format) throw new Error('No formats specified for FormatPipe');

    const formats: Format[] = asArray(format || []);

    // If the value is undefined or null, then we want to avoid errors by skipping all formatting -- except we still honor REPLACE_EMPTY if it's specified.
    if (hasNoValue(value)) return formats.includes('REPLACE_EMPTY') ? this.transformForOneFormat('', 'REPLACE_EMPTY') : '';

    return formats.reduce(
      (accumulator: string, currentFormat: Format) => this.transformForOneFormat(accumulator, currentFormat),
      isDate(value) ? toDateTimeIsoString(value) : String(value)
    );
  }

  private readonly replaceEmptyPipe: ReplaceEmptyPipe = inject(ReplaceEmptyPipe);
  private readonly phonePipe: PhonePipe = inject(PhonePipe);
  private readonly titleCasePipe: TitleCasePipe = inject(TitleCasePipe);
  private readonly upperCasePipe: UpperCasePipe = inject(UpperCasePipe);
  private readonly datePipe: DatePipe = inject(DatePipe);
  private readonly currencyPipe: CurrencyPipe = inject(CurrencyPipe);
  private readonly characterMaskPipe: CharacterMaskPipe = inject(CharacterMaskPipe);
  private readonly decimalPipe: DecimalPipe = inject(DecimalPipe);
  private readonly yesOrNoPipe: YesOrNoPipe = inject(YesOrNoPipe);
  private readonly percentPipe: PercentPipe = inject(PercentPipe)

  private transformForOneFormat(string: string, format: Format): string {
    switch (format) {
      case 'REPLACE_EMPTY':
        return this.replaceEmptyPipe.transform(string);

      case 'YES_OR_NO':
        return this.yesOrNoPipe.transform(string === 'true')

      case 'NUMBER':
      case 'CURRENCY':
      case 'PERCENT_AS_IS':
      case 'PERCENT_X_100':
        return this.transformNumber(string, format);

      case 'PHONE':
        return this.phonePipe.transform(string);

      case 'TITLE_CASE':
        return this.titleCasePipe.transform(string);

      case 'UPPER_CASE':
        return this.upperCasePipe.transform(string);

      case 'DEFAULT_CHARACTER_MASK':
        return this.characterMaskPipe.transform(string);

      case 'DATE_ONLY':
        return this.transformDate(string, DATE_FORMAT);

      case 'LONG_DATE_ONLY':
        return this.transformDate(string, LONG_DATE_FORMAT);

      case 'SHORT_DATE_ONLY':
        return this.transformDate(string, SHORT_DATE_FORMAT);

      case 'DATE_TIME':
        return this.forceLowerCaseAmPmIn(this.transformDate(string, `${DATE_FORMAT} ${TIME_FORMAT}`));

      case 'LONG_DATE_TIME':
        return this.forceLowerCaseAmPmIn(this.transformDate(string, `${LONG_DATE_FORMAT} ${TIME_FORMAT}`));

      case 'DATE_AT_TIME':
        return this.forceLowerCaseAmPmIn(this.transformDate(string, `${DATE_FORMAT} 'at' ${TIME_FORMAT}`));

      case 'DATE_ONLY_RANGE':
        return this.transformDateRange(string, DATE_FORMAT);

      case 'TIME_ONLY':
        return this.forceLowerCaseAmPmIn(this.transformDate(string, TIME_FORMAT));

      case 'HOUR_ONLY':
        // Don't factor in local time zone when converting to HOUR_FORMAT.  Might need to support local times, too?  If so, we can expose a new pipe argument.
        return this.forceLowerCaseAmPmIn(this.transformDate(string, 'haaa', '+0'));

      case 'DATE_FULL_WEEKDAY_ONLY':
        return this.transformDate(string, 'cccc');

      case 'DATE_ABBREVIATED_WEEKDAY_ONLY':
        return this.transformDate(string, 'ccc');

      case 'DATE_ABBREVIATED_MONTH_ONLY':
        return this.transformDate(string, 'LLL');

      case 'DATE_DAY_NUMBER_ONLY':
        return this.transformDate(string, 'd');
    }

    return string;
  }

  // Skip the timeZone argument when calling this if you want it to do the default thing and factor in the user's local time zone when formatting date/time.
  private transformDate(string: string, dateFormat: string, timeZone?: string): string {
    if (!isDateString(string)) return string;

    return this.datePipe.transform(string, dateFormat, timeZone) ?? '';
  }

  private transformNumber(string: string, numberFormat: string): string {
    if (string === 'NaN') return '';
    switch (numberFormat) {
      case 'NUMBER':
        return this.decimalPipe.transform(string) ?? '0';

      case 'CURRENCY':
        return this.transformCurrency(string);

      case 'PERCENT_AS_IS':
        return (this.decimalPipe.transform(string, PERCENT_DIGIT_INFO) ?? '0') + '%';

      case 'PERCENT_X_100':
        return this.percentPipe.transform(string, PERCENT_DIGIT_INFO) ?? '0';
    }
    return string;
  }

  private transformDateRange(string: string, dateFormat: string): string {
    if (!isDateOnlyRangeIsoString(string)) return string;

    const [start, end] = string.split('..').map(date => this.transformDate(date, dateFormat));

    return start === end ? start : `${start}-${end}`;
  }

  private transformCurrency(string: string): string {
    return this.currencyPipe.transform(string) ?? '';
  }

  private forceLowerCaseAmPmIn(string: string): string {
    // See comment on TIME_FORMAT above.
    return string.replace(/AM/, 'am').replace(/PM/, 'pm');
  }
}
