import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  inject,
  Input,
  QueryList,
  TrackByFunction,
  Type,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  AnyMxSelectorCreatorMatching,
  MxSelector,
  MxSelectorCreator,
  MxSelectorCreatorNameMatchingWithReturnType,
  MxSelectorNameMatching
} from '@store/store.types';
import {Sort} from '@angular/material/sort';
import {RouterActions} from '@store/router/router.actions';
import {createSelector, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {isArray, isDefined, isPopulatedArray, isPopulatedString, isSelector, isString, isUndefined} from '@store/common/typing.helpers';
import {
  ActionCreator,
  ActionIconButtonDefinition,
  ALWAYS,
  CellComponentProperties,
  CellComponentProperty,
  CellCssClassDefinition,
  ChipColumnDefinition,
  ClickHandler,
  ColumnDefinition,
  ComponentColumnDefinition,
  ComponentExpansionCellDefinition,
  CURRENT_ROW_OBJECT,
  CURRENT_SELECTORS_CONTAINER,
  ExpansionCellDefinition,
  HiddenDataIndicatorRowDefinition,
  KebabActionDefinition,
  LinkRouteParamSelectedSelectorCreators,
  LinkRouteParamSelectorCreatorNames,
  LoadableSelectorContainer,
  NoArgumentActionCreator,
  NoArgumentClickHandler,
  RouteNameLinkColumnDefinition,
  RowCssClassDefinition,
  RowSelectionTooltips,
  SelectorCreatorOrSelectorCreatorNameOrSimply,
  SimpleLinkColumnDefinition,
  StatusChipColumnDefinition,
  TableDefinition,
  TextColumnDefinition,
  TextExpansionCellDefinition
} from './table.component.types';
import {OneOrArrayOf} from '@store/utility-type.helpers';
import {RowSelectionService} from './row-selection/row-selection.service';
import {RowExpansionService} from './row-expansion/row-expansion.service';
import {trackByIndex, TrackByIndexFunction, trackByProperty} from '@store/common/track-by.helpers';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {camelCaseToTitleCase} from '@store/transformation.helpers';
import {SelectPipe} from '../core/shared/pipes/select-pipe/select.pipe';
import {DynamicComponentHostDirective} from './dynamic-component-host/dynamic-component-host.directive';
import {NO_OP, RV_STANDARD_ANIMATION_TIMINGS, StatusChipColors} from '@store/common/common.types';
import {CommonSelectors, PaginationSelectors} from '@store/store.selectors';
import {Format} from '../core/shared/pipes/format.pipe';

type InternalTextColumnDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  Omit<Required<TextColumnDefinition<ObjectType, SelectorsContainer>>, 'type' | 'value'> &
  {
    valueSelectorCreator?: AnyMxSelectorCreatorMatching<ObjectType>;
    columnName: string;
  };

type InternalSimpleLinkColumnDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  Omit<Required<SimpleLinkColumnDefinition<ObjectType, SelectorsContainer>>, 'type' | 'value'> &
  {
    valueSelectorCreator?: AnyMxSelectorCreatorMatching<ObjectType>;
    columnName: string;
    isLink: true;
    isSimpleLink: true;
    clickHandler: ClickHandler<ObjectType>,
  };

type InternalRouteNameLinkColumnDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  Omit<Required<RouteNameLinkColumnDefinition<ObjectType, SelectorsContainer>>, 'type' | 'value'> &
  {
    valueSelectorCreator?: AnyMxSelectorCreatorMatching<ObjectType>;
    columnName: string;
    isLink: true;
    isRouteNameLink: true;
  };

type InternalChipColumnDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  Omit<Required<ChipColumnDefinition<ObjectType, SelectorsContainer>>, 'type' | 'value' | 'chipColor'> &
  {
    valueSelectorCreator?: AnyMxSelectorCreatorMatching<ObjectType>;
    columnName: string;
    isChip: true;
    chipColorFor: (rowObject: ObjectType) => string;
  };

type InternalStatusChipColumnDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  Omit<Required<StatusChipColumnDefinition<ObjectType, SelectorsContainer>>, 'type' | 'value'> &
  {
    valueSelectorCreator?: AnyMxSelectorCreatorMatching<ObjectType>;
    columnName: string;
    isChip: true;
    isStatusChip: true;
  };

type InternalComponentColumnDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  Omit<Required<ComponentColumnDefinition<ObjectType, SelectorsContainer>>, 'type'> &
  {
    isComponent: true;
  };

type InternalColumnDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  Required<Omit<ColumnDefinition<ObjectType, SelectorsContainer>, 'selectorCreatorName' | 'actionCreator'>> &
  {
    type: ColumnDefinition<ObjectType, SelectorsContainer>['type'],
    columnName: string;
    valueSelectorCreator?: AnyMxSelectorCreatorMatching<ObjectType>;
    isComponent: boolean;
    componentClass?: Type<any>;  // eslint-disable-line @typescript-eslint/no-explicit-any -- Generic component typing is difficult, maybe impossible.
    isLink: boolean;
    clickHandler: ClickHandler<ObjectType>,
    isSimpleLink: boolean;
    isRouteNameLink: boolean;
    routeName: string;
    routeParams: LinkRouteParamSelectorCreatorNames<ObjectType, SelectorsContainer>;
    format: OneOrArrayOf<Format>;
    cssClasses: CellCssClassDefinition<ObjectType, SelectorsContainer>;
    truncate: boolean;
    isChip: boolean;
    chipColorFor: (rowObject: ObjectType) => string;
    isStatusChip: boolean;
    statusChipColors: StatusChipColors<string>;
    displayableStatusNames: Map<string, string>;
  };

type InternalActionIconButtonDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  Required<Omit<
    ActionIconButtonDefinition<ObjectType, SelectorsContainer>,
    'clickActionCreator' | 'color' | 'tooltip' | 'badge' | 'superscript' | 'visible' | 'enabled'
  >> & {
  colorFor: (rowObject: ObjectType) => string,
  tooltipFor: (rowObject: ObjectType) => string,
  badgeFor: (rowObject: ObjectType) => string,
  superscriptFor: (rowObject: ObjectType) => string,
  visibleFor: (rowObject: ObjectType) => boolean,
  enabledFor: (rowObject: ObjectType) => boolean
};

type InternalKebabActionDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  Required<Omit<KebabActionDefinition<ObjectType, SelectorsContainer>, 'clickActionCreator' | 'visible'>> &
  {
    showMenuItemFor: (rowObject: ObjectType) => boolean
  };

type InternalExpansionCellDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  InternalTextExpansionCellDefinition<ObjectType, SelectorsContainer>
  | InternalComponentExpansionCellDefinition<ObjectType, SelectorsContainer>;

type InternalTextExpansionCellDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  Required<Omit<TextExpansionCellDefinition<ObjectType, SelectorsContainer>, 'type' | 'header' | 'value'>> &
  {
    hasTextValue: true;
    hasComponentValue: false;
    headerFor: (rowObject: ObjectType) => string;
    valueFor: (rowObject: ObjectType) => string;
  };

type InternalComponentExpansionCellDefinition<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  Required<Omit<ComponentExpansionCellDefinition<ObjectType, SelectorsContainer>, 'type' | 'header' | 'componentProperties'>> &
  {
    hasTextValue: false;
    hasComponentValue: true;
    headerFor: (rowObject: ObjectType) => string;
    componentProperties: CellComponentProperties<ObjectType, SelectorsContainer>;
  };

interface InternalRowSelectionTooltips {
  whenUnselected: string;
  whenSelected: string;
  whenDisabled: string;
}

interface DynamicComponentDirectiveData<ObjectType, SelectorsContainer extends LoadableSelectorContainer> {
  row: ObjectType;
  column: InternalComponentColumnDefinition<ObjectType, SelectorsContainer>;
  componentClass: Type<any>;  // eslint-disable-line @typescript-eslint/no-explicit-any -- Generic component typing is difficult, maybe impossible.
  componentProperties: CellComponentProperties<ObjectType, SelectorsContainer>;
}

type DynamicComponentDirective<ObjectType, SelectorsContainer extends LoadableSelectorContainer> =
  DynamicComponentHostDirective<DynamicComponentDirectiveData<ObjectType, SelectorsContainer>>;

type DynamicComponentType = 'CELL' | 'EXPANSION_CELL' | 'EXPANSION_ROW';

const ICON_BUTTON_WIDTH_IN_REMS = 2.5; // For the default 40x40px icon button.
const GENERATED_COLUMN_NAME_PREFIX = 'GENERATED_COLUMN_NAME';
const MISSING_HEADER_PREFIX = 'MISSING_HEADER';
const MISSING_SORT_KEY_PREFIX = 'MISSING_SORT_KEY';

@Component({
  selector: 'rn-table',
  templateUrl: './table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RowSelectionService, RowExpansionService],
  animations: [
    trigger('expansionRowContentTransition', [
      state('collapsed', style({height: 0, 'padding-top': 0, 'padding-bottom': 0})),
      state('expanded', style({height: '*', 'padding-top': '*', 'padding-bottom': '*'})),
      transition('collapsed <=> expanded', animate(RV_STANDARD_ANIMATION_TIMINGS))
    ])
  ]
})
export class TableComponent<ObjectType, DefaultSelectorContainer extends LoadableSelectorContainer> implements AfterViewInit {

  @ViewChildren('cellComponentContainer', {read: DynamicComponentHostDirective}) dynamicCellComponentHostDirectiveQueryList:
    QueryList<DynamicComponentDirective<ObjectType, DefaultSelectorContainer>>;
  @ViewChildren('expansionCellComponentContainer', {read: DynamicComponentHostDirective}) dynamicExpansionCellComponentHostDirectiveQueryList:
    QueryList<DynamicComponentDirective<ObjectType, DefaultSelectorContainer>>;
  @ViewChildren('rowComponentContainer', {read: DynamicComponentHostDirective}) dynamicRowComponentHostDirectiveQueryList:
    QueryList<DynamicComponentDirective<ObjectType, DefaultSelectorContainer>>;

  @ViewChild('customNoDataComponentContainer', {read: DynamicComponentHostDirective}) dynamicNoDataComponentHostDirective: DynamicComponentHostDirective<never>;

  @Input() set tableDefinition(definition: TableDefinition<ObjectType, DefaultSelectorContainer>) {
    this.defaultSelectorContainer = definition.defaultSelectorContainer;

    this.tableName = String(definition.dataSource);

    this.dataSourceSelector =
      isString(definition.dataSource)
        ? this.defaultSelectorContainer[String(definition.dataSource)]
        : definition.dataSource;

    this.dataSource = this.store.select(this.dataSourceSelector);
    this.rowSelectionChangeAction = definition.rowSelectionChangeAction;
    this.rowSelectionEnabledFor = this.generateRowValueFunctionFor(definition.individualRowIsSelectable, true);
    this.rowSelectionTooltipsFor = this.generateRowSelectionTooltipsValueFunctionFor(definition.rowSelectionTooltips);

    this.rowSelectionDataSourceSelector = createSelector(
      this.dataSourceSelector,
      data => data?.filter(row => this.rowSelectionEnabledFor(row)) ?? []
    );

    this.rowCountSelector = createSelector(
      this.dataSourceSelector,
      data => data?.length ?? 0
    );

    this.actionButtons = this.generateActionButtonsFrom(definition.actionIconButtons);
    this.showActionButtons = isPopulatedArray(this.actionButtons);
    this.kebabMenuActions = this.generateKebabMenuActionsFrom(definition.kebabMenuActions);
    this.showKebabMenuActions = isPopulatedArray(this.kebabMenuActions);
    this.rowCssClasses = definition.rowCssClasses ?? {};
    this.noDataComponentClass = definition.noDataComponentClass;
    this.noDataRowMessageText = definition.noDataRowMessage ?? 'No items to show.'
    this.noDataRowMessage = createSelector(() => this.noDataRowMessageText);
    this.useStandardNoDataMessageRow = !this.noDataComponentClass;
    this.useCustomNoDataComponent = !!this.noDataComponentClass;
    this.useExpansionCells = (definition.expansionCells?.length ?? 0) > 0;
    this.showExpansionControlFor = this.generateRowValueFunctionFor(definition.showExpansionControlIf, true);
    this.expansionCellDefinitions = this.generateExpansionCellsFrom(definition.expansionCells);
    this.useExpansionComponents = definition.enableExpansionRowComponent ?? false;
    this.expansionRowComponentClass = definition.expansionRowComponentClass;
    this.useExpansionRows = this.useExpansionCells || this.useExpansionComponents;
    this.showPagination = definition.includePaginationControls ?? true;

    if (isDefined(definition.entityId)) {
      const entityIdSelector: MxSelector<number> =
        isString(definition.entityId)
          ? this.defaultSelectorContainer[String(definition.entityId)]
          : definition.entityId;

      this.entityId = this.selectPipe.transform(entityIdSelector);
    }

    this.initializeHiddenDataIndicatorRowFrom(definition.hiddenDataIndicatorRow);

    this.calculateActionsColumnWidth();
    this.buildInternalColumnsFrom(definition);

    this.numberOfDataColumns = this.columns.length;
    this.columnOrder = [
      ...this.useExpansionRows ? ['_expansionRowToggle'] : [],
      ...definition.enableRowSelection ? ['_rowSelectionCheckboxes'] : [],
      ...this.columns.map(column => column.columnName),
      ...this.showActionButtons || this.showKebabMenuActions ? ['_actions'] : []
    ];

    this.columnOrderForNoData = this.columnOrder.filter(columnName => columnName !== '_rowSelectionCheckboxes');
  }

// These will be replaced with the appropriate Observables based on the tableDefinition input.  We give them initial values to improve the initial rendering.
  public dataSource: Observable<ObjectType[]> = of([]);
  public actionsColumnWidthInRems: Observable<number> = of(0);
  public defaultSelectorContainer: DefaultSelectorContainer;

  public tableName: string;
  public columnOrder: string[];
  public columnOrderForNoData: string[];
  public columns: InternalColumnDefinition<ObjectType, DefaultSelectorContainer>[];
  public numberOfDataColumns = 0;
  public actionButtons: InternalActionIconButtonDefinition<ObjectType, DefaultSelectorContainer>[];
  public showActionButtons: boolean;
  public kebabMenuActions: InternalKebabActionDefinition<ObjectType, DefaultSelectorContainer>[];
  public showKebabMenuActions: boolean;
  public rowCssClasses?: RowCssClassDefinition<ObjectType, DefaultSelectorContainer>;
  public rowSelectionEnabledFor: (row: ObjectType) => boolean;
  public rowSelectionTooltipsFor: (row: ObjectType) => InternalRowSelectionTooltips;
  public rowSelectionDataSourceSelector: MxSelector<ObjectType[]>;
  public rowCountSelector: MxSelector<number>;
  public rowSelectionChangeAction?: ActionCreator<ObjectType[]>;
  public readonly paginationSelectors = PaginationSelectors;
  public useStandardNoDataMessageRow: boolean;
  public noDataRowMessage: MxSelector<string>;
  public useCustomNoDataComponent: boolean;
  public useExpansionCells: boolean;
  public showExpansionControlFor: (row: ObjectType) => boolean;
  public useExpansionComponents: boolean;
  public readonly rowExpansionService = inject(RowExpansionService);
  public showPagination: boolean;
  public useHiddenDataIndicatorRow = false;
  public hiddenDataIndicatorRowMessage: MxSelector<string>;
  public hiddenDataIndicatorRowButtonLabel: MxSelector<string>;
  public hiddenDataIndicatorRowClickHandler: NoArgumentClickHandler;
  public showHiddenDataIndicatorRow: MxSelector<boolean>;
  public hiddenDataIndicatorColumnOrder: MxSelector<string[]>;

  public readonly trackByColumnName: TrackByFunction<InternalColumnDefinition<ObjectType, DefaultSelectorContainer>> =
    trackByProperty<InternalColumnDefinition<ObjectType, DefaultSelectorContainer>>('columnName');

  public readonly trackByKebabMenuItemLabel: TrackByFunction<InternalKebabActionDefinition<ObjectType, DefaultSelectorContainer>> =
    trackByProperty<InternalKebabActionDefinition<ObjectType, DefaultSelectorContainer>>('label');

  public readonly trackByIndex: TrackByIndexFunction = trackByIndex;

  public ngAfterViewInit(): void {
    this.dynamicCellComponentHostDirectiveQueryList.changes
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((directives: DynamicComponentDirective<ObjectType, DefaultSelectorContainer>[]) =>
        this.createDynamicComponentsFor(directives, 'CELL')
      );

    this.dynamicExpansionCellComponentHostDirectiveQueryList.changes
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((directives: DynamicComponentDirective<ObjectType, DefaultSelectorContainer>[]) =>
        this.createDynamicComponentsFor(directives, 'EXPANSION_CELL')
      );

    this.dynamicRowComponentHostDirectiveQueryList.changes
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((directives: DynamicComponentDirective<ObjectType, DefaultSelectorContainer>[]) =>
        this.createDynamicComponentsFor(directives, 'EXPANSION_ROW')
      );

    if (this.noDataComponentClass) {
      const viewContainer = this.dynamicNoDataComponentHostDirective.viewContainer;
      viewContainer.clear();
      viewContainer.createComponent<Component>(this.noDataComponentClass);
      this.changeDetector.detectChanges();
    }
  }

  public buildLinkParamsFor(column: InternalColumnDefinition<ObjectType, DefaultSelectorContainer>, row: ObjectType): LinkRouteParamSelectedSelectorCreators {
    return Object.entries(column.routeParams).reduce(
      (accumulator, [paramName, paramValue]) => {
        const selectorCreator = this.getSelectorCreatorFor(paramValue);
        if (!isDefined(selectorCreator)) {
          return accumulator;
        }
        return {
          ...accumulator,
          [paramName]: this.selectPipe.transform(selectorCreator(row))
        }
      },
      {} as LinkRouteParamSelectedSelectorCreators
    );
  }

  public generateRowCssClassesFor(row: ObjectType): string {
    const initialRowClasses: string = this.useExpansionRows ? 'hk-expandable-row' : '';

    if (!this.rowCssClasses) return initialRowClasses;

    return Object.entries(this.rowCssClasses).reduce(
      (accumulator, [className, selectorCreatorName]) =>
        this.selectPipe.transform(this.defaultSelectorContainer[String(selectorCreatorName)](row))
          ? `${accumulator} ${className}`
          : accumulator,
      initialRowClasses
    );
  }

  public generateCellCssClassesFor(row: ObjectType, cellCssClasses: CellCssClassDefinition<ObjectType, DefaultSelectorContainer>): string {
    if (!cellCssClasses) return '';

    return Object.entries(cellCssClasses).reduce(
      (accumulator, [className, selectorCreatorOrSelectorCreatorName]) =>
        selectorCreatorOrSelectorCreatorName === ALWAYS || this.generateRowValueFunctionFor(selectorCreatorOrSelectorCreatorName, false)(row)
          ? `${accumulator} ${className}`
          : accumulator,
      ''
    );
  }

  public onMatSortChange(angularSortState: Sort): void {
    this.store.dispatch(RouterActions.updateSortParams(angularSortState, this.entityId));
  }

  public visibleKebabMenuActionsFor(row: ObjectType): InternalKebabActionDefinition<ObjectType, DefaultSelectorContainer>[] | undefined {
    const visibleActions: InternalKebabActionDefinition<ObjectType, DefaultSelectorContainer>[] =
      this.kebabMenuActions.filter(menuAction => menuAction.showMenuItemFor(row));

    // Specifically return undefined instead of empty array to help the template know whether to display the kabob menu at all.
    return visibleActions.length > 0 ? visibleActions : undefined;
  }

  public expansionCellDefinitionsFor(columnIndex: number): InternalExpansionCellDefinition<ObjectType, DefaultSelectorContainer>[] {
    return this.expansionCellDefinitions?.filter((_, index) => columnIndex === index % this.numberOfDataColumns) ?? [];
  }

  private dataSourceSelector: MxSelector<ObjectType[]>;
  private store: Store = inject(Store);
  private selectPipe: SelectPipe = inject(SelectPipe);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  private noDataComponentClass?: Type<any>;  // eslint-disable-line @typescript-eslint/no-explicit-any -- Generic component typing is difficult.
  private expansionRowComponentClass?: Type<Component>;
  private expansionCellDefinitions: InternalExpansionCellDefinition<ObjectType, DefaultSelectorContainer>[];
  private useExpansionRows: boolean;
  private entityId?: number;
  private noDataRowMessageText: string;

  private getSelectorCreatorFor<T>(
    definitionPropertyValue?: string | MxSelectorCreatorNameMatchingWithReturnType<ObjectType, DefaultSelectorContainer, T> | MxSelectorCreator<[ObjectType], T>
  ): MxSelectorCreator<[ObjectType], T> | undefined {
    if (isUndefined(definitionPropertyValue)) return undefined;

    return isString(definitionPropertyValue)
      ? this.defaultSelectorContainer[String(definitionPropertyValue)]
      : definitionPropertyValue;
  }

  private generateRowValueFunctionFor<T>(
    definitionPropertyValue:
      string | MxSelectorCreatorNameMatchingWithReturnType<ObjectType, DefaultSelectorContainer, T> | MxSelectorCreator<[ObjectType], T> | undefined,
    fallbackValue: T
  ): (rowObject: ObjectType) => T {
    const selectorCreator = this.getSelectorCreatorFor<T>(definitionPropertyValue);

    return isDefined(selectorCreator)
      ? (rowObject: ObjectType) => this.selectPipe.transform(selectorCreator(rowObject), fallbackValue)
      : () => fallbackValue;
  }

  private generateRowSelectionTooltipsValueFunctionFor(
    rowSelectionTooltips?: RowSelectionTooltips<ObjectType, DefaultSelectorContainer>
  ): (rowObject: ObjectType) => InternalRowSelectionTooltips {
    const whenUnselected: SelectorCreatorOrSelectorCreatorNameOrSimply<string, ObjectType, DefaultSelectorContainer> =
      rowSelectionTooltips?.whenUnselected ?? '';
    const whenSelected: SelectorCreatorOrSelectorCreatorNameOrSimply<string, ObjectType, DefaultSelectorContainer> =
      rowSelectionTooltips?.whenSelected ?? '';
    const whenDisabled: SelectorCreatorOrSelectorCreatorNameOrSimply<string, ObjectType, DefaultSelectorContainer> =
      rowSelectionTooltips?.whenDisabled ?? '';

    return (rowObject: ObjectType) => ({
      whenUnselected: this.generateRowValueFunctionFor(whenUnselected, isString(whenUnselected) ? whenUnselected : '')(rowObject),
      whenSelected: this.generateRowValueFunctionFor(whenSelected, isString(whenSelected) ? whenSelected : '')(rowObject),
      whenDisabled: this.generateRowValueFunctionFor(whenDisabled, isString(whenDisabled) ? whenDisabled : '')(rowObject),
    });
  }

  private calculateActionsColumnWidth(): void {
    this.actionsColumnWidthInRems = this.store.select(createSelector(
      this.dataSourceSelector,
      rows => {
        const mostActionButtonsVisibleInAnyRow: number =
          rows
            ? rows.reduce(
              (accumulator, row) => Math.max(accumulator, this.countVisibleActionButtonsIn(row)),
              0
            )
            : 1;

        return (mostActionButtonsVisibleInAnyRow * ICON_BUTTON_WIDTH_IN_REMS) + (this.showKebabMenuActions ? ICON_BUTTON_WIDTH_IN_REMS : 0);
      }
    ));
  }

  private countVisibleActionButtonsIn(row: ObjectType): number {
    return this.actionButtons.filter(actionButton => actionButton.visibleFor(row)).length;
  }

  private buildInternalColumnsFrom(tableDefinition: TableDefinition<ObjectType, DefaultSelectorContainer>): void {
    const FALLBACK_COLUMN: InternalColumnDefinition<ObjectType, DefaultSelectorContainer> = {
      type: 'text',
      valueSelectorCreator: undefined,
      columnName: '',
      header: '',
      headerCssClasses: '',
      headerToolTip: '',
      sortable: false,
      sortKey: '',
      isComponent: false,
      isChip: false,
      isStatusChip: false,
      isLink: false,
      isSimpleLink: false,
      isRouteNameLink: false,
      clickHandler: NO_OP,
      routeName: '',
      routeParams: {},
      format: 'NONE',
      cssClasses: {},
      truncate: false,
      chipColorFor: () => '',
      statusChipColors: {
        'status-positive': [],
        'status-neutral': [],
        'status-negative': [],
        'status-transitional': [],
        'status-inactive': []
      },
      displayableStatusNames: new Map()
    };

    this.columns = tableDefinition.columns.map((columnDefinition, columnIndex) => {
      const columnName: string = this.generateColumnNameFrom(columnDefinition, columnIndex);
      const header: string = this.generateColumnHeaderFrom(columnDefinition, columnName, columnIndex);
      const headerCssClasses: string = columnDefinition.headerCssClasses ?? FALLBACK_COLUMN.headerCssClasses;
      const headerToolTip: string = columnDefinition.headerToolTip ?? FALLBACK_COLUMN.headerToolTip;
      const sortable: boolean = columnDefinition.sortable ?? false;
      const sortKey: string = this.generateColumnSortKeyFrom(columnDefinition, columnName, columnIndex);

      if (columnDefinition.type === 'component') {
        const internalColumn: InternalComponentColumnDefinition<ObjectType, DefaultSelectorContainer> = {
          columnName,
          header,
          headerCssClasses,
          headerToolTip,
          sortable,
          sortKey,
          isComponent: true,
          componentClass: columnDefinition.componentClass,
          componentProperties: columnDefinition.componentProperties ?? {}
        };

        return {
          ...FALLBACK_COLUMN,
          ...internalColumn
        };
      }

      const valueSelectorCreator: AnyMxSelectorCreatorMatching<ObjectType> | undefined = this.getSelectorCreatorFor(columnDefinition.value);

      const format: OneOrArrayOf<Format> = columnDefinition.format ?? 'NONE';
      const cssClasses: CellCssClassDefinition<ObjectType, DefaultSelectorContainer> = columnDefinition.cssClasses ?? {};
      const truncate: boolean = columnDefinition.truncate ?? false;

      if (columnDefinition.type === 'text') {
        const internalColumn: InternalTextColumnDefinition<ObjectType, DefaultSelectorContainer> = {
          columnName,
          header,
          headerCssClasses,
          headerToolTip,
          sortable,
          sortKey,
          valueSelectorCreator,
          format,
          cssClasses,
          truncate
        };

        return {
          ...FALLBACK_COLUMN,
          ...internalColumn,
        };
      }

      if (columnDefinition.type === 'routeNameLink') {
        const internalColumn: InternalRouteNameLinkColumnDefinition<ObjectType, DefaultSelectorContainer> = {
          columnName,
          header,
          headerCssClasses,
          headerToolTip,
          sortable,
          sortKey,
          valueSelectorCreator,
          format,
          cssClasses,
          truncate,
          isLink: true,
          isRouteNameLink: true,
          routeName: columnDefinition.routeName,
          routeParams: columnDefinition.routeParams ?? {}
        };

        return {
          ...FALLBACK_COLUMN,
          ...internalColumn
        };
      }

      if (columnDefinition.type === 'chip') {
        const internalColumn: InternalChipColumnDefinition<ObjectType, DefaultSelectorContainer> = {
          columnName,
          header,
          headerCssClasses,
          headerToolTip,
          sortable,
          sortKey,
          valueSelectorCreator,
          format,
          cssClasses,
          truncate,
          isChip: true,
          chipColorFor: this.generateRowValueFunctionFor(columnDefinition.chipColor, 'status-inactive')
        };

        return {
          ...FALLBACK_COLUMN,
          ...internalColumn
        };
      }

      if (columnDefinition.type === 'statusChip') {
        const internalColumn: InternalStatusChipColumnDefinition<ObjectType, DefaultSelectorContainer> = {
          columnName,
          header,
          headerCssClasses,
          headerToolTip,
          sortable,
          sortKey,
          valueSelectorCreator,
          format,
          cssClasses,
          truncate,
          isChip: true,
          isStatusChip: true,
          statusChipColors: columnDefinition.statusChipColors,
          displayableStatusNames: columnDefinition.displayableStatusNames ?? new Map()
        };

        return {
          ...FALLBACK_COLUMN,
          ...internalColumn
        };
      }

      // At this point, columnDefinition.type is either 'simpleLink' or 'actionLink', both of which end up internally as simple links.
      const internalColumn: InternalSimpleLinkColumnDefinition<ObjectType, DefaultSelectorContainer> = {
        columnName,
        header,
        headerCssClasses,
        headerToolTip,
        sortable,
        sortKey,
        valueSelectorCreator,
        format,
        cssClasses,
        truncate,
        isLink: true,
        isSimpleLink: true,
        clickHandler: this.generateLinkClickHandlerFor(columnDefinition) ?? NO_OP
      };


      return {
        ...FALLBACK_COLUMN,
        ...internalColumn
      };
    });
  }

  private generateColumnNameFrom(columnDefinition: ColumnDefinition<ObjectType, DefaultSelectorContainer>, columnIndex: number): string {
    if (columnDefinition.type === 'component') return columnDefinition.columnName;
    if (isString(columnDefinition.value)) return columnDefinition.value.replace(/For$/, '');

    return `${GENERATED_COLUMN_NAME_PREFIX}_${columnIndex}`;
  }

  private generateColumnHeaderFrom(columnDefinition: ColumnDefinition<ObjectType, DefaultSelectorContainer>, columnName: string, columnIndex: number): string {
    if (columnDefinition.header) return columnDefinition.header;

    return columnName.startsWith(GENERATED_COLUMN_NAME_PREFIX)
      ? `${MISSING_HEADER_PREFIX}_${columnIndex}`
      : camelCaseToTitleCase(columnName);
  }

  private generateColumnSortKeyFrom(columnDefinition: ColumnDefinition<ObjectType, DefaultSelectorContainer>, columnName: string, columnIndex: number): string {
    if (columnDefinition.sortKey) return columnDefinition.sortKey;

    return columnName.startsWith(GENERATED_COLUMN_NAME_PREFIX)
      ? `${MISSING_SORT_KEY_PREFIX}_${columnIndex}`
      : columnName;
  }

  private generateLinkClickHandlerFor(columnDefinition: ColumnDefinition<ObjectType, DefaultSelectorContainer>): ClickHandler<ObjectType> | undefined {
    return this.generateClickHandlerFor(
      columnDefinition.type === 'actionLink' ? columnDefinition.actionCreator : undefined,
      columnDefinition.type === 'simpleLink' ? columnDefinition.clickHandler : undefined
    );
  }

  private generateClickHandlerFor<ObjectType>(
    clickActionCreator?: ActionCreator<ObjectType>, clickHandler?: ClickHandler<ObjectType>
  ): ClickHandler<ObjectType> | undefined {
    return clickActionCreator
      ? (rowObject: ObjectType) => this.store.dispatch(clickActionCreator(rowObject))
      : clickHandler;
  }

  private generateNoArgumentClickHandlerFor(
    clickActionCreator?: NoArgumentActionCreator, clickHandler?: NoArgumentClickHandler
  ): NoArgumentClickHandler | undefined {
    return clickActionCreator
      ? () => this.store.dispatch(clickActionCreator())
      : clickHandler;
  }

  private generateActionButtonsFrom(
    actionButtons?: ActionIconButtonDefinition<ObjectType, DefaultSelectorContainer>[]
  ): InternalActionIconButtonDefinition<ObjectType, DefaultSelectorContainer>[] {
    if (!actionButtons) return [];

    return actionButtons.map(actionButtonDefinition => ({
      ...actionButtonDefinition,
      colorFor: this.generateRowValueFunctionFor(actionButtonDefinition.color, 'surface-variant-contrast'),
      clickHandler: this.generateClickHandlerFor(actionButtonDefinition.clickActionCreator, actionButtonDefinition.clickHandler) ?? NO_OP,
      cssClasses: actionButtonDefinition.cssClasses ?? {},
      tooltipFor:
        this.generateRowValueFunctionFor(actionButtonDefinition.tooltip, isString(actionButtonDefinition.tooltip) ? actionButtonDefinition.tooltip : ''),
      badgeFor:
        this.generateRowValueFunctionFor(actionButtonDefinition.badge, isString(actionButtonDefinition.badge) ? actionButtonDefinition.badge : ''),
      superscriptFor:
        this.generateRowValueFunctionFor(
          actionButtonDefinition.superscript, isString(actionButtonDefinition.superscript) ? actionButtonDefinition.superscript : ''
        ),
      visibleFor: this.generateRowValueFunctionFor(actionButtonDefinition.visible, true),
      enabledFor: this.generateRowValueFunctionFor(actionButtonDefinition.enabled, true)
    }));
  }

  private generateKebabMenuActionsFrom(
    kebabMenuActions?: KebabActionDefinition<ObjectType, DefaultSelectorContainer>[]
  ): InternalKebabActionDefinition<ObjectType, DefaultSelectorContainer>[] {
    if (!kebabMenuActions) return [];

    return kebabMenuActions.map(kebabActionDefinition => {
      const selectorCreator = this.getSelectorCreatorFor<boolean>(kebabActionDefinition.visible);

      return ({
        ...kebabActionDefinition,
        showMenuItemFor:
          isDefined(selectorCreator)
            ? (rowObject: ObjectType) => this.selectPipe.transform(selectorCreator(rowObject), false)
            : () => true,
        clickHandler: this.generateClickHandlerFor(kebabActionDefinition.clickActionCreator, kebabActionDefinition.clickHandler) ?? NO_OP
      });
    });
  }

  private generateExpansionCellsFrom(
    expansionCells?: ExpansionCellDefinition<ObjectType, DefaultSelectorContainer>[]
  ): InternalExpansionCellDefinition<ObjectType, DefaultSelectorContainer>[] {
    if (!expansionCells) return [];

    return expansionCells.map(expansionCellDefinition => {
      const headerFor: (rowObject: ObjectType) => string =
        this.generateRowValueFunctionFor(expansionCellDefinition.header, isString(expansionCellDefinition.header) ? expansionCellDefinition.header : '');

      return expansionCellDefinition.type === 'text'
        ? {
          hasTextValue: true,
          hasComponentValue: false,
          headerFor,
          valueFor:
            this.generateRowValueFunctionFor(expansionCellDefinition.value, isString(expansionCellDefinition.value) ? expansionCellDefinition.value : ''),
          format: expansionCellDefinition.format ?? 'NONE'
        }
        : {
          hasTextValue: false,
          hasComponentValue: true,
          headerFor,
          componentClass: expansionCellDefinition.componentClass,
          componentProperties: expansionCellDefinition.componentProperties ?? {}
        };
    });
  }

  private createDynamicComponentsFor(directives: DynamicComponentDirective<ObjectType, DefaultSelectorContainer>[], type: DynamicComponentType) {
    directives.forEach((directive: DynamicComponentDirective<ObjectType, DefaultSelectorContainer>) => this.createDynamicComponentFor(directive, type));

    this.changeDetector.detectChanges();
  }

  private createDynamicComponentFor(directive: DynamicComponentDirective<ObjectType, DefaultSelectorContainer>, type: DynamicComponentType) {
    const containerData: DynamicComponentDirectiveData<ObjectType, DefaultSelectorContainer> = directive.data;
    const viewContainer = directive.viewContainer;
    viewContainer.clear();

    switch (type) {
      case 'CELL': {
        const containerColumn = containerData.column;
        const componentRef: ComponentRef<Component> = viewContainer.createComponent<Component>(containerColumn.componentClass);

        this.assignPropertiesIn(componentRef, containerColumn.componentProperties, containerData.row);
        componentRef.location.nativeElement.classList.add('max-w-full'); // Ensure that dynamic components don't take more space than their containers.

        break;
      }

      case 'EXPANSION_CELL': {
        const componentRef: ComponentRef<Component> = viewContainer.createComponent<Component>(containerData.componentClass);

        this.assignPropertiesIn(componentRef, containerData.componentProperties, containerData.row);
        componentRef.location.nativeElement.classList.add('max-w-full'); // Ensure that dynamic components don't take more space than their containers.

        break;
      }

      case 'EXPANSION_ROW': {
        if (this.expansionRowComponentClass) {
          const componentRef: ComponentRef<Component> = viewContainer.createComponent<Component>(this.expansionRowComponentClass);

          componentRef.instance['row'] = containerData.row;
        }
      }
    }
  }

  private assignPropertiesIn(
    componentRef: ComponentRef<Component>, properties: CellComponentProperties<ObjectType, DefaultSelectorContainer>, rowObject: ObjectType
  ) {
    Object.entries(properties).forEach(([propertyName, definitionPropertyValue]) =>
      componentRef.instance[propertyName] = this.createComponentPropertyValueFrom(definitionPropertyValue, rowObject)
    );
  }

  private createComponentPropertyValueFrom(definitionPropertyValue: CellComponentProperty<ObjectType, DefaultSelectorContainer>, rowObject: ObjectType) {
    switch (definitionPropertyValue) {
      case true:
        return true;
      case false:
        return false;
      case CURRENT_ROW_OBJECT:
        return rowObject;
      case CURRENT_SELECTORS_CONTAINER:
        return this.defaultSelectorContainer;
    }

    if (isArray(definitionPropertyValue)) return definitionPropertyValue;

    const selectorCreator = this.getSelectorCreatorFor(definitionPropertyValue);
    return selectorCreator ? this.selectPipe.transform(selectorCreator(rowObject)) : definitionPropertyValue;
  }

  private initializeHiddenDataIndicatorRowFrom(definition?: HiddenDataIndicatorRowDefinition<DefaultSelectorContainer>): void {
    if (isUndefined(definition)) return;

    this.useHiddenDataIndicatorRow = true;
    this.showHiddenDataIndicatorRow = this.getSelectorFor(definition.showIf, false);

    this.hiddenDataIndicatorColumnOrder = createSelector(
      this.showHiddenDataIndicatorRow,
      show => show ? [this.columnOrder[0]] : []
    );

    if (isPopulatedString(definition.overriddenNoDataRowMessageWhenShowing)) {
      this.noDataRowMessage = createSelector(
        this.showHiddenDataIndicatorRow,
        show => show ? definition.overriddenNoDataRowMessageWhenShowing as string : this.noDataRowMessageText
      );
    }

    this.hiddenDataIndicatorRowMessage = this.getStringSelectorFor(definition.message);
    this.hiddenDataIndicatorRowButtonLabel = this.getStringSelectorFor(definition.actionButtonLabel ?? 'Show');
    this.hiddenDataIndicatorRowClickHandler = this.generateNoArgumentClickHandlerFor(definition.clickActionCreator, definition.clickHandler) ?? NO_OP;
  }


  private getSelectorFor<T>(stringOrSelector: string | MxSelectorNameMatching<T, DefaultSelectorContainer> | MxSelector<T>, fallbackValue: T): MxSelector<T> {
    if (isSelector<T>(stringOrSelector)) return stringOrSelector;

    if (isString(stringOrSelector)) {
      const selectorFromContainer = this.defaultSelectorContainer[stringOrSelector];

      if (isSelector<T>(selectorFromContainer)) return selectorFromContainer;
    }

    return createSelector(() => fallbackValue);
  }

  private getStringSelectorFor(stringOrSelector: string | MxSelectorNameMatching<string, DefaultSelectorContainer> | MxSelector<string>): MxSelector<string> {
    if (isSelector<string>(stringOrSelector)) return stringOrSelector;

    if (isString(stringOrSelector)) {
      const selectorFromContainer = this.defaultSelectorContainer[stringOrSelector];

      return isSelector<string>(selectorFromContainer) ? selectorFromContainer : createSelector(() => stringOrSelector);
    }

    return CommonSelectors.alwaysEmptyString;
  }
}
