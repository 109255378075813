import {AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, inject, OnDestroy} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

interface HeightAdjusterTriggerData {
  value: number;
  params: {
    [key: string]: number;
  }
}

@Component({
  selector: 'rn-table-height-adjuster',
  host: {
    class: 'block overflow-hidden'
  },
  template: `
    <ng-content></ng-content>
  `,
  animations: [
    trigger('adjustHeight', [
      transition(
        ':increment, :decrement',
        [
          style({height: '{{currentHeight}}px'}),
          animate('500ms ease-in-out'),
        ],
        {
          params: {currentHeight: 0}
        }
      )
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeightAdjusterComponent implements AfterContentInit, OnDestroy {

  @HostBinding('@adjustHeight') triggerData: HeightAdjusterTriggerData;

  private readonly hostElement: HTMLElement = inject(ElementRef).nativeElement;
  private readonly changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  private rowMutationObserver: MutationObserver;
  private currentHeight: number = 0;

  public ngAfterContentInit(): void {
    const tbodyElement: HTMLTableSectionElement | null = this.hostElement.querySelector('tbody');
    if (!tbodyElement) return;

    this.rowMutationObserver = new MutationObserver(this.onMutationsObserved.bind(this));
    this.rowMutationObserver.observe(tbodyElement, {childList: true});

    // "Prime the pump", and start with a non-zero value so that the first real animation transition actually does something.
    this.triggerAnimationIfCurrentHeightDiffersFrom(this.hostElement.clientHeight || 1);
  }

  public ngOnDestroy(): void {
    this.rowMutationObserver?.disconnect();
  }

  private onMutationsObserved(): void {
    // This particular callback doesn't use its "mutations" argument because we're specifically listening for child updates, and all we have to do is remeasure.

    this.triggerAnimationIfCurrentHeightDiffersFrom(this.hostElement.clientHeight);
  }

  private triggerAnimationIfCurrentHeightDiffersFrom(newHeight: number): void {
    if (newHeight === this.currentHeight) return;

    this.triggerData = {
      value: newHeight,                            // Send the new height to trigger the transition...
      params: {currentHeight: this.currentHeight}  // ...but send the current height before we update it, so that the animation starts from the right place.
    };

    this.currentHeight = newHeight;
    this.changeDetector.markForCheck();
  }
}
