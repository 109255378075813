import {inject, Injectable, OnDestroy} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {ActionCreator} from '../table.component.types';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';

type AddOrRemove = 'add' | 'remove';

@Injectable({providedIn: 'root'})
export class RowSelectionService<RowObject> implements OnDestroy {

  public tableElement: HTMLTableElement;
  public noRowsAreSelectable: boolean;

  public set data(data: RowObject[]) {
    this._data = data;
    this._dataCount = data.length;
    this.noRowsAreSelectable = this._dataCount === 0;
    this.deselectAllRows();
  }

  public set rowSelectionChangeAction(rowSelectionChangeAction: ActionCreator<RowObject[]> | undefined) {
    if (this.selectionModelChangeSubscription) this.selectionModelChangeSubscription.unsubscribe();

    if (rowSelectionChangeAction) {
      this.selectionModelChangeSubscription = this.selectionModel.changed.subscribe(() =>
        this.store.dispatch(rowSelectionChangeAction(this.selectionModel.selected ?? []))
      );
    }
  }

  public ngOnDestroy(): void {
    if (this.selectionModelChangeSubscription) this.selectionModelChangeSubscription.unsubscribe();
  }

  public get allRowsAreSelected(): boolean {
    return !this.noRowsAreSelectable && this.selectionModel.selected.length === this._data.length;
  }

  public get someRowsAreSelected(): boolean {
    return this.selectionModel.hasValue() && !this.allRowsAreSelected;
  }

  public get allRowsAreSelectable(): boolean {
    return this._dataCount === this.findAllTableRows().length;
  }

  public isSelected(rowObject: RowObject): boolean {
    return this.selectionModel.isSelected(rowObject);
  }

  public toggleSelectionFor(rowObject: RowObject, tableRow: HTMLTableRowElement): void {
    this.selectionModel.toggle(rowObject);
    this.updatedSelectedClassFor(tableRow, this.selectionModel.isSelected(rowObject) ? 'add' : 'remove');
  }

  public selectAllRows(): void {
    this.selectionModel.select(...this._data);
    this.updateSelectedClassForAllSelectableRows('add');
  }

  public deselectAllRows(): void {
    this.selectionModel.clear();
    this.updateSelectedClassForAllSelectableRows('remove');
  }

  private _data: RowObject[] = [];
  private _dataCount: number = 0;
  private readonly selectionModel = new SelectionModel<RowObject>(true);
  private selectionModelChangeSubscription: Subscription;
  private readonly store: Store = inject(Store);

  private updatedSelectedClassFor(tableRow: HTMLTableRowElement, addOrRemove: AddOrRemove): void {
    tableRow.classList[addOrRemove]('hk-selected-row');
  }

  private updateSelectedClassForAllSelectableRows(addOrRemove: AddOrRemove): void {
    for (const tableRow of this.findAllSelectableTableRows()) {
      this.updatedSelectedClassFor(tableRow, addOrRemove);
    }
  }

  private findAllTableRows(): HTMLTableRowElement[] {
    return this.findTableRowsMatching('tbody tr');
  }

  private findAllSelectableTableRows(): HTMLTableRowElement[] {
    return this.findTableRowsMatching('tbody tr:has(.mat-mdc-checkbox:not(.mat-mdc-checkbox-disabled))');
  }

  private findTableRowsMatching(selector: string): HTMLTableRowElement[] {
    if (!this.tableElement) return [];

    return Array.from(this.tableElement.querySelectorAll(selector));
  }
}
