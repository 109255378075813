import {Injectable} from '@angular/core';
import {Actions, createEffect} from '@ngrx/effects';
import {filter, map} from 'rxjs';
import {PaginationActions} from '@store/pagination/pagination.actions';
import {isObject} from '@store/common/typing.helpers';
import {isListData} from '@store/common/common.types';
import {PaginationInfo} from '@store/pagination/pagination.types';

@Injectable({providedIn: 'root'})
export class PaginationEffects {
  updateFromAppropriateActions = createEffect(() =>
    this.actions.pipe(
      map(action => isObject(action) && isListData(action.listData) ? action.listData.paginationInfo : undefined),
      filter(paginationInfo => !!paginationInfo),
      map(paginationInfo => PaginationActions.update(paginationInfo as PaginationInfo))
    )
  );

  constructor(private readonly actions: Actions) {
  }
}

