import {animate, state, style, transition, trigger,} from '@angular/animations';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, TrackByFunction} from '@angular/core';
import {CURRENT_ROW_OBJECT, TableDefinition} from '../../table/table.component.types';
import {CheckoutsSelectors, PatronSelectors} from '@store/store.selectors';
import {CombinedCoverTitleBarcodeComponent} from '../shared/combined-cover-title-barcode/combined-cover-title-barcode.component';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Checkout as LegacyCheckout, RoutesService} from '@raven';
import {trackById} from '@store/common/track-by.helpers';
import {PatronCheckoutsActionsComponent} from './patron-checkouts-actions.component';
import {CheckoutService} from './checkout.service';
import {Checkout, CheckoutClaim} from '@store/checkouts/checkouts.types';

@Component({
  selector: 'rn-patron-checkouts',
  templateUrl: './patron-checkouts.component.html',
  styleUrls: ['./patron-checkouts.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatronCheckoutsComponent {
  public readonly accountStatusSelector = PatronSelectors.AccountStatus;
  public readonly routingService = inject(RoutesService);
  public readonly selectors = CheckoutsSelectors.CheckedOut;
  public readonly claimsSelectors = CheckoutsSelectors.Claims;
  public readonly trackCheckoutsById: TrackByFunction<Checkout> = trackById;
  public readonly trackClaimsById: TrackByFunction<CheckoutClaim> = trackById;
  public isMobile = false;
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly changeRef = inject(ChangeDetectorRef);
  private readonly checkoutService = inject(CheckoutService);

  constructor() {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntilDestroyed())
      .subscribe((breakpointState: BreakpointState) => {
        this.isMobile = breakpointState.matches;
        this.changeRef.markForCheck();
      });
  }

  public readonly checkoutsTableDefinition: TableDefinition<Checkout, typeof CheckoutsSelectors.CheckedOut> = {
    defaultSelectorContainer: CheckoutsSelectors.CheckedOut,
    dataSource: 'checkouts',
    columns: [
      {
        type: 'text',
        value: 'dueDateFor',
        header: 'Due Date',
        format: 'DATE_ONLY',
        cssClasses: {
          'text-error': 'overDueFor'
        },
        headerCssClasses: 'table-column-width-small',
        sortable: true,
      },
      {
        type: 'component',
        columnName: 'item',
        componentClass: CombinedCoverTitleBarcodeComponent,
        componentProperties: {
          titleText: 'itemTitleFor',
          barcode: 'itemBarcodeFor',
          catalogRecordId: 'catalogRecordIdFor',
          imageUrl: 'imageUrlFor'
        },
        sortable: true,
        sortKey: 'title'
      },
      {
        type: 'text',
        value: 'itemMediaTypeFor',
        header: 'Material Type',
        headerCssClasses: 'table-column-width-small',
        sortable: true,
        sortKey: 'materialType'
      },
      {
        type: 'text',
        value: 'checkoutTimeFor',
        header: 'Check Out Date',
        format: 'DATE_ONLY',
        headerCssClasses: 'table-column-width-small',
        sortable: true,
      },
      {
        type: 'component',
        columnName: 'action',
        headerCssClasses: 'table-column-width-small',
        componentClass: PatronCheckoutsActionsComponent,
        componentProperties: {
          checkout: CURRENT_ROW_OBJECT
        },
      },
    ]
  };

  public readonly claimsTableDefinition: TableDefinition<CheckoutClaim, typeof CheckoutsSelectors.Claims> = {
    defaultSelectorContainer: CheckoutsSelectors.Claims,
    dataSource: 'claims',
    includePaginationControls: false,
    columns: [
      {
        type: 'text',
        value: 'createdFor',
        header: 'Submitted',
        format: 'DATE_ONLY',
        headerCssClasses: 'table-column-width-small'
      },
      {
        type: 'component',
        columnName: 'item',
        componentClass: CombinedCoverTitleBarcodeComponent,
        componentProperties: {
          titleText: 'itemTitleFor',
          barcode: 'itemBarcodeFor',
          catalogRecordId: 'catalogRecordIdFor',
          imageUrl: 'imageUrlFor'
        }
      },
      {
        type: 'text',
        value: 'itemMediaTypeFor',
        header: 'Material Type',
        headerCssClasses: 'table-column-width-small'
      },
    ],
    kebabMenuActions: [
      {
        label: 'View Claim',
      }
    ],
  };

  renewCheckout(items: Checkout[], canRenew: boolean): void {
    //TODO update checkout actions to use rxjs in order to resolve the checkout and legacy checkout types
    this.checkoutService.showRenewCheckout(items as unknown as LegacyCheckout[], canRenew);
  }

  hasRenewableItems(items: Checkout[], canRenew: boolean): boolean {
    //TODO update checkout actions to use rxjs in order to resolve the checkout and legacy checkout types
    return this.checkoutService.getRenewableItems(items as unknown as LegacyCheckout[], canRenew).length > 0;
  }
}
