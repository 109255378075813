import {Injectable} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';

type AddOrRemove = 'add' | 'remove';

@Injectable({providedIn: 'root'})
export class RowExpansionService<RowObject> {

  private readonly selectionModel = new SelectionModel<RowObject>(true);

  public isExpanded(rowObject: RowObject): boolean {
    return this.selectionModel.isSelected(rowObject);
  }

  public toggleExpansionFor(rowObject: RowObject, tableRow: HTMLTableRowElement): void {
    this.selectionModel.toggle(rowObject);

    const addOrRemove: AddOrRemove = this.selectionModel.isSelected(rowObject) ? 'add' : 'remove'
    this.updateClassForRowAbove(tableRow, addOrRemove)
    this.updateClassForRow(tableRow, addOrRemove);
  }

  private updateClassForRowAbove(tableRow: HTMLTableRowElement, addOrRemove: AddOrRemove): void {
    const previousTableRow: Element | null = tableRow.previousElementSibling;

    if (previousTableRow?.nodeName.toLowerCase() === 'tr') previousTableRow.classList[addOrRemove]('rn-expandable-row-next-row-expanded');
  }

  private updateClassForRow(tableRow: HTMLTableRowElement, addOrRemove: AddOrRemove): void {
    tableRow.classList[addOrRemove]('rn-expandable-row-expanded');
  }
}
