import {createActionGroup, emptyProps} from '@ngrx/store';
import {Patron, PATRON_FEATURE_KEY, PatronAccountStatus, PatronRegistrationValue, RegistrationStep} from './patron.types';

export const PatronActions = createActionGroup({
  source: PATRON_FEATURE_KEY,
  events: {
    'Initialize Auth Token': emptyProps(),
    'Initialize Auth Token Success': (authToken: string) => ({authToken}),
    'Initialize Auth Token No Auth': emptyProps(),
    'Initialize Auth Token Failure': (error: unknown) => ({error}),

    'Reload Auth Patron': emptyProps(),
    'Load Auth Patron Success': (patron: Patron) => ({patron}),
    'Load Auth Patron Failure': (error: unknown) => ({error}),

    'Login Patron': (email: string, password: string) => ({email, password}),
    'Register Login Patron': (email: string, password: string) => ({email, password}),
    'Login Patron Success': emptyProps(),
    'Login Patron Failure': (error: unknown) => ({error}),
    'Logout Patron': emptyProps(),
    'Reset Patron Password': (email: string) => ({email}),

    'Change Password': (oldPassword: string, newPassword: string) => ({oldPassword, newPassword}),
    'Change Password Success': emptyProps(),
    'Change Password Authentication Failure': (error: unknown) => ({error}),
    'Change Password Failure': (error: unknown) => ({error}),

    'Load Account Status': emptyProps(),
    'Load Account Status Success': (status: PatronAccountStatus) => ({status}),
    'Load Account Status Failure': (error: unknown) => ({error}),

    'Update Registration': (value: Partial<PatronRegistrationValue>) => ({value}),
    'Complete Registration Step': (step: RegistrationStep) => ({step}),

    'Lookup Account': (cardNumber: string, PIN: string) => ({cardNumber, PIN}),
    'Lookup Account Success': (cardNumber: string, PIN: string, patron: Patron) => ({cardNumber, PIN, patron}),
    'Lookup Account Failure': (error: unknown) => ({error}),

    'Validate Patron Email Available': (email: string) => ({email}),
    'Validate Patron Email Available Success': (isAvailable: boolean) => ({isAvailable}),
    'Validate Patron Email Available Failure': (error: unknown) => ({error}),

    'Request Verification Email': (email: string) => ({email}),
    'Request Verification Email Success': (email: string, verificationUUID: string) => ({email, verificationUUID}),
    'Request Verification Email Failure': (error: unknown) => ({error}),
    'Resend Verification Email': emptyProps(),

    'Verify Email': (code: string) => ({code}),
    'Verify Email Success': emptyProps(),
    'Verify Email Failure': (error: unknown) => ({error}),

    'Register': emptyProps(),
    'Register Success': emptyProps(),
    'Register Failure': (error: unknown) => ({error}),

    'Register Existing': emptyProps(),
    'Register Existing Success': emptyProps(),
    'Register Existing Failure': (error: unknown) => ({error}),
  }
});
