import {createActionGroup} from '@ngrx/store';
import {QUERY_PARAMS_FEATURE_KEY} from '@store/query-params/query-params.types';
import {Params} from '@angular/router';

export const QueryParamsActions = createActionGroup({
  source: QUERY_PARAMS_FEATURE_KEY,
  events: {
    'Save': (routeName: string, queryParams: Params) => ({routeName, queryParams}),
    'Clear': (routeName: string) => ({routeName})
  }
});
