import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Environment} from '@raven';
import {Params} from '@angular/router';
import {map, Observable} from 'rxjs';
import {buildQueryParamsFrom, extractListData} from '@store/common/list-data.helpers';
import {ListData, ResponseBase} from '@store/common/common.types';
import {Checkout, CheckoutClaim} from './checkouts.types';

@Injectable({providedIn: 'root'})
export class CheckoutsService {

  private readonly http: HttpClient = inject(HttpClient);
  private readonly environment: Environment = inject(Environment);

  public loadCheckedOut(queryParams: Params): Observable<ListData<Checkout>> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/checkouts`;
    const params: HttpParams = buildQueryParamsFrom(queryParams);

    return this.http.get<ResponseBase<Checkout>>(url, {params}).pipe(
      map(response => extractListData<Checkout>(response))
    );
  }

  public loadCheckoutClaims(): Observable<ListData<CheckoutClaim>> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/checkouts/claims`;

    return this.http.get<ResponseBase<CheckoutClaim>>(url).pipe(
      map(response => extractListData<CheckoutClaim>(response, [], false))
    );
  }
}
