import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {StatusChipColor, StatusChipColors} from '@store/common/common.types';

@Component({
  selector: 'rn-status-chip',
  host: {
    class: 'flex flex-col items-center'
  },
  template: `
    <mat-chip [color]="color" class="hk-status-chip" [class.h-7]="hasSubscriptText">
      <ng-container *ngIf="useContent; else useDisplayableStatus">
        <ng-content></ng-content>
      </ng-container>

      <ng-template #useDisplayableStatus>
        {{ displayableStatus | format:'TITLE_CASE' }}
      </ng-template>
    </mat-chip>

    <span *ngIf="hasSubscriptText" class="text-label-small">
      {{ subscriptText }}
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusChipComponent<T extends string> implements OnChanges {
  @Input() status?: T | null;
  @Input() colors: StatusChipColors<T>;
  @Input() displayableStatusNames: Map<T, string> = new Map();
  @Input() useContent?: boolean = false; // Until Angular can detect whether <ng-content> is populated, this is simple and reliable, though slightly annoying.
  @Input() subscriptText?: string;

  public displayableStatus = '?';
  public color: StatusChipColor;
  public hasSubscriptText = false;

  public ngOnChanges(): void {
    this.displayableStatus = (this.status && this.displayableStatusNames.get(this.status)) ?? this.status ?? '?';

    /*
      find() narrows down the StatusChipColors object entries to something like ['status-neutral', ['STATUS_1', 'STATUS_2']],
      so then the [0] element contains the 'status-neutral' color.
    */
    const entryContainingStatus: [string, T[]] | undefined =
      Object.entries(this.colors ?? {}).find(([, statuses]) => !!this.status && statuses.includes(this.status));

    this.color = (entryContainingStatus?.[0] as StatusChipColor) ?? 'status-inactive';

    this.hasSubscriptText = !!this.subscriptText;
  }
}
