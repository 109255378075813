import {MxSelector, MxSelectorCreator} from '@store/store.types';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {QueryParamsState} from './query-params.state';
import {QUERY_PARAMS_FEATURE_KEY} from '@store/query-params/query-params.types';
import {Params} from '@angular/router';

export const entireState: MxSelector<QueryParamsState> = createFeatureSelector(QUERY_PARAMS_FEATURE_KEY);

export const hasCachedParamsFor: MxSelectorCreator<[string], boolean> =
  routeName => createSelector(
    entireState,
    state => routeName in state
  );

export const cachedParamsFor: MxSelectorCreator<[string], Params> =
  routeName => createSelector(
    entireState,
    state => state[routeName] ?? {}
  );
