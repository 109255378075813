import {Params} from '@angular/router';
import {PATH_FOR} from '../../app.routes';

export function buildValidatedRouterPathFrom(routeName: string, params: Params): string {
  const rawRouterPath: string = getRawRouterPathFor(routeName);

  validateParamsFor(rawRouterPath, params, routeName);

  return substituteParamsIn(rawRouterPath, params);
}

//
// Local functions
//
function isParameterPlaceholder(segment: string): boolean {
  return segment.startsWith(':');
}

function asParameterName(segment: string): string {
  return segment.replace(/^:/, '');
}

function substituteParamsIn(rawPath: string, params: Params): string {
  return rawPath
    .split('/')
    .map(segment => isParameterPlaceholder(segment) ? params[asParameterName(segment)] : segment)
    .join('/');
}

function getRawRouterPathFor(routeName: string): string {
  const rawRouterPath: string = PATH_FOR[routeName];
  if (rawRouterPath) return rawRouterPath;

  throw new Error(`Can't find '${routeName}' in NAMED_ROUTE_PATHS.  Is this name defined in some route's data?`);
}


function validateParamsFor(rawRouterPath: string, params: Params, routeName: string): void {
  const routeParamNames: string [] =
    rawRouterPath
      .split('/')
      .filter(segment => isParameterPlaceholder(segment))
      .map(segment => asParameterName(segment));

  const specifiedParamNames: string[] = Object.keys(params);

  checkForMissingParams(routeParamNames, specifiedParamNames, routeName, rawRouterPath);
  checkForExtraParams(routeParamNames, specifiedParamNames, routeName, rawRouterPath);
}

function checkForMissingParams(routeParamNames: string[], specifiedParamNames: string[], routeName: string, rawRouterPath: string): void {
  const missingParamValues: string[] = routeParamNames.filter(routeParam => !specifiedParamNames.includes(routeParam));

  if (missingParamValues.length > 0) {
    throw new Error(
      `'${routeName}' maps to the path '${rawRouterPath}', but the [hkRouterLinkParams] input is missing the following parameters: `
      + missingParamValues.join(', ')
    );
  }
}

function checkForExtraParams(routeParamNames: string[], specifiedParamNames: string[], routeName: string, rawRouterPath: string): void {
  const extraParamValues: string[] = specifiedParamNames.filter(specifiedParam => !routeParamNames.includes(specifiedParam));

  if (extraParamValues.length > 0) {
    throw new Error(
      `'${routeName}' maps to the path '${rawRouterPath}', and the [hkRouterLinkParams] input supplies the following unneeded parameters: `
      + extraParamValues.join(', ')
    );
  }
}
