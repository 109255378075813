<div class="flex-row">
  <!--TODO display a cover image
  <div class="item">
    <rn-fallback-image [imageUrls]="[imageUrl]" [alt]="titleText"></rn-fallback-image>
  </div>-->
  <div>
    <a (click)="routingService.goToCatalogRecordDetail(catalogRecordId)">{{ titleText }}</a>
    <div class="mat-caption">{{ barcode }}</div>
  </div>
</div>
