import {isNumber, isObject} from '@store/common/typing.helpers';

export const PAGINATION_FEATURE_KEY = 'pagination';

export interface PaginationInfo {
  page: number;
  pageSize: number;
  totalCount: number;
}

export const DEFAULT_PAGINATION_INFO: PaginationInfo = {
  page: 1,
  pageSize: 5,
  totalCount: 0
}

export function isPaginationInfo(thing: unknown): thing is PaginationInfo {
  return isObject(thing) && isNumber(thing.page) && isNumber(thing.pageSize) && isNumber(thing.totalCount);
}
