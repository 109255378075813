import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {Checkout} from '@raven';
import {CheckoutService} from './checkout.service';
import {PatronSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-patron-checkouts-actions',
  template: `
    <ng-container *ngIf="{
      noBlocks: accountStatusSelector.noBlocks | select,
      underMaxBalance: accountStatusSelector.underMaxBalance | select,
    } as vm">
      <div class="flex-row-col-responsive" *ngIf="accountStatusSelector.status as accountStatus">
        <span (click)="renewCheckout([checkout], vm.noBlocks)" [matTooltip]="getTooltipText(checkout, vm.underMaxBalance)"
              [ngClass]="{ 'disabled-icon': !isRenewable(checkout, vm.noBlocks) }" class="ri-refresh-line action-icon pointer"
              matTooltipPosition="above"></span>
        <span (click)="submitClaim(checkout)" *ngIf="!checkout.claimId"
              class="ri-draft-line action-icon pointer" matTooltip="Submit Claim"
              matTooltipPosition="above"></span>
      </div>
    </ng-container>
  `,
  styles: [`
      .action-icon {
          font-size: 1.25rem;
          padding: 0.5rem;
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatronCheckoutsActionsComponent {
  public readonly accountStatusSelector = PatronSelectors.AccountStatus;
  private readonly checkoutService = inject(CheckoutService);

  @Input() checkout: Checkout;

  renewCheckout(items: Checkout[], canRenew: boolean): void {
    this.checkoutService.showRenewCheckout(items, canRenew);
  }

  submitClaim(checkout: Checkout): void {
    return this.checkoutService.submitClaim(checkout);
  }

  getTooltipText(item: Checkout, underMaxBalance: boolean): string {
    if (item.claimId > 0) {
      return 'Item is ineligible for renewal'
    }
    if (item.holdExists) {
      return 'Item has holds, cannot renew';
    }
    if (!underMaxBalance) {
      return 'Fee limit reached, cannot renew';
    }
    return 'Renew';
  }

  isRenewable(checkout: Checkout, canRenew: boolean): boolean {
    return this.checkoutService.isRenewable(checkout, canRenew);
  }
}
