import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, map} from 'rxjs';
import {Action} from '@ngrx/store';
import {ROUTER_NAVIGATED} from '@ngrx/router-store';
import {withLatestFromSelectors} from '@store/common/effects.helpers';
import {RouterSelectors} from '@store/store.selectors';
import {QueryParamsActions} from './query-params.actions';

@Injectable({providedIn: 'root'})
export class QueryParamsEffects {
  saveQueryParamsOnRouterNavigated = createEffect(() =>
    this.actions.pipe(
      ofType<Action>(ROUTER_NAVIGATED),
      withLatestFromSelectors(RouterSelectors.routeName, RouterSelectors.queryParams),
      filter(([, routeName]) => !!routeName),
      map(([, routeName, queryParams]) => QueryParamsActions.save(routeName as string, queryParams))
    )
  );

  constructor(private readonly actions: Actions) {
  }
}

