import {DestroyRef, inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable, switchMap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Checkout, CirculationItemClaimedReturned, ConfirmationDialogComponent, Environment, NotificationService, ResponseBase} from '@raven';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CheckoutsActions} from '@store/checkouts/checkouts.actions';
import {ClaimReturnedDialog} from './dialogs/claims-dialog';

@Injectable({providedIn: 'root'})
export class CheckoutService {
  private readonly environment = inject(Environment);
  private readonly http = inject(HttpClient);
  private readonly dialog = inject(MatDialog);
  private readonly notificationService = inject(NotificationService);
  private readonly store = inject(Store);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  getRenewableItems(items: Checkout[], canRenew: boolean): Checkout[] {
    const renewItems: Checkout[] = [];
    for (const item of items) {
      if (this.isRenewable(item, canRenew)) {
        renewItems.push(item);
      }
    }
    return renewItems;
  }

  hasOverdueItem(items: Checkout[]): boolean {
    for (const item of items) {
      if (item.dueDays < 0) {
        return true;
      }
    }
    return false;
  }

  showRenewCheckout(items: Checkout[], canRenew: boolean): void {
    const renewItems = this.getRenewableItems(items, canRenew);
    if (renewItems.length === 0) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Renew Item (' + renewItems.length + ')',
        message:
          renewItems.length > 1
            ? 'Are you sure you want to renew the selected items?'
            : 'Are you sure you want to renew this item?',
        warning: this.hasOverdueItem(renewItems)
          ? 'Overdue fines will remain due, but renewing will stop new fines from accruing from this point' +
          ' forward.'
          : '',
        confirmButton: 'Yes, Renew',
      },
      maxWidth: '95vw',
    });
    dialogRef.afterClosed()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((confirmed: boolean) => {
          if (confirmed) {
            if (renewItems.length === 1) {
              return this.renewCheckout(renewItems[0].checkoutId);
            } else {
              return this.renewCheckoutList(renewItems.map((item) => item.checkoutId));
            }
          }
          return EMPTY;
        }),
      )
      .subscribe((result) => {
        if (Array.isArray(result)) {
          this.notificationService.showSnackbarSuccess(
            `${result.length} items have been successfully renewed.`
          );
        } else {
          this.notificationService.showSnackbarSuccess(
            '1 item has been successfully renewed.'
          );
        }
        this.store.dispatch(CheckoutsActions.loadCheckedOut());
      });
  }

  submitClaim(checkout: Checkout): void {
    const dialogRef = this.dialog.open(ClaimReturnedDialog, {
      panelClass: 'dialog-no-pad',
      autoFocus: false,
      data: {checkout},
      maxWidth: '95vw',
      maxHeight: '90vh',
    });
    dialogRef.afterClosed()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((claim: CirculationItemClaimedReturned) =>
          (claim?.checkoutId && claim?.type)
            ? this.claimReturned(claim)
            : EMPTY
        )
      )
      .subscribe((claim) => {
        if (claim) {
          this.notificationService.showSnackbarSuccess('Claim has been submitted.');
          this.store.dispatch(CheckoutsActions.loadCheckedOut());
          this.store.dispatch(CheckoutsActions.loadCheckoutClaims());
        }
      });
  }

  isRenewable(checkout: Checkout, canRenew: boolean): boolean {
    return canRenew && !checkout.holdExists && checkout.claimId === null;
  }

  claimReturned(claimReturnedRequest: CirculationItemClaimedReturned): Observable<CirculationItemClaimedReturned> {
    const url = `${this.environment.apiUrl}/patrons/v2/patrons/checkouts/claims`;
    return this.http.post<ResponseBase<CirculationItemClaimedReturned>>(url, claimReturnedRequest)
      .pipe(
        catchError(() => {
          this.notificationService.showSnackbarError('Unable to submit claim. Please try again later.');
          return EMPTY;
        }),
        map(rb => rb.objects[0])
      );
  }

  renewCheckout(checkoutId: number): Observable<Checkout> {
    const url = `${this.environment.apiUrl}/patrons/v2/checkouts/${checkoutId}/renew`;
    return this.http.patch<ResponseBase<Checkout>>(url, '').pipe(
      catchError(() => {
        this.notificationService.showSnackbarError('Unable to renew item. Please try again later.');
        return EMPTY;
      }),
      map(rb => rb.objects[0])
    );
  }

  renewCheckoutList(checkoutIds: number[]): Observable<Checkout[]> {
    const url = `${this.environment.apiUrl}/patrons/v2/checkouts/renew`;
    return this.http.patch<ResponseBase<Checkout[]>>(url, checkoutIds).pipe(
      catchError(() => {
        this.notificationService.showSnackbarError('Unable to renew items. Please try again later.');
        return EMPTY;
      }),
      map(rb => rb.objects[0])
    );
  }
}
